import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformCity'
})
export class TransformCityPipe implements PipeTransform {

  transform(value: string): string {
    if (value.split(' ')[0] === 'KOTA') {
      return value.replace('KOTA ', '').toLowerCase().replace(/\b\w/g, char => char.toUpperCase())
    }
    return null;
  }

}
