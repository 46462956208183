import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumberPipe'
})
export class ShortNumberPipePipe implements PipeTransform {

  transform(value: number): string {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'miliar';
    }
    else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'jt';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'rb';
    } else {
      return value.toString();
    }
  }
}
