import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";
import { AddAddress } from "../accounts/profile/myprofile/address/address.component";
import { ConfirmAddressComponent } from "../accounts/profile/myprofile/address/confirm-address/confirm-address.component";
import { SelectAddressComponent } from "./select-address/select-address.component";
import {
  faBasketShopping,
  faMoneyBill,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SelectServiceComponent } from "./select-service/select-service/select-service.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectVoucherComponent } from "./select-voucher/select-voucher.component";
import { DialogAlert } from "../accounts/profile/myprofile/detail-profile/detail-profile.component";
import Swal from "sweetalert2";
import { map, tap } from "rxjs/operators";
import { CheckServicePayload } from "../models/checkout/checkout";
import { forkJoin } from "rxjs";

// interface Payment {
//   value: string;
//   viewValue: string;
// }

interface ProvinceData {
  country_name: string;
  province_name: string;
  city_name: string;
  sub_district_name: string;
  urban_name: string;
  postal_code: string;
  id: number;
  city_id?: number;
}

interface AddressData {
  recipient: string;
  phone: string;
  category: string;
  province: string;
  city: string;
  district: string;
  urban: string;
  post_id: string;
  address_input: string;
  osas_log_id: number;
  address: ProvinceData;
  address_id?: string;
  primary_address: boolean;
}

interface ServiceData {
  service_id: any;
  cod: boolean;
  desc: string;
  eta: string;
  maxKg: number;
  minKg: number;
  multiplier: number;
  name: string;
  price: number;
  serviceId: number;
  total: number;
  shipping_fee: number;
  service_name: string;
}

interface QueryTable {
  limit: number;
  pageIndex: number;
}

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  isLoading: boolean = false;
  pageEvent: PageEvent;
  primaryAddress: any;
  listOfData: AddressData = {} as AddressData;
  dataSource = new MatTableDataSource<AddressData>([this.listOfData]);
  selectedAddressId: any;
  selectedServiceId: number;
  selectedServiceIds: number[] = [];
  deliveryPrice: number = 0;
  faBasketShopping = faBasketShopping;
  faTicket = faTicket;
  faMoney = faMoneyBill;
  orderInfo: any[];
  productInfo: any[];
  reviewInfo: any[] = [];
  store_name: string;
  productTotalPrice: number = 0;
  overallPrice: number = 0;
  subTotalPrice: number = 0;
  checkFeeParam = {
    originId: 0,
    destinationId: 0,
    weight: 0,
    dimension: {
      length: 0,
      height: 0,
      width: 0,
    },
  };
  checkFeeParams: CheckServicePayload;
  services: ServiceData[] = [];
  selectedExpedition: string = "Pilih Service";
  selectedExpeditions: string[] = [];
  selectedExpeditionsPrice: number[] = [];
  selectedCodAcitivated: boolean;

  vouchers = new Array();
  productDetails: any;
  form: FormGroup;
  newVouchers: any;
  testDD: any;
  voucherId: any;
  voucherName: string;
  voucherCode: string;
  insuranceUsed: boolean = false;
  insuranceFee: number = 0;
  discountedPrice: number = 0;
  cartId: number = null;
  insuranceUsedArray: boolean[] = [];
  notesArray: string[] = [];

  // NOTE: Global params for select services
  store_id: number;
  location_id: number;

  @ViewChild(MatTable, { static: true }) table: MatTable<any> =
    Object.create(null);
  message: any;
  freightCost: any;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private routes: Router,
    private router: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      notes: [null],
      payment: ["", Validators.required],
    });
  }

  ngOnInit() {
    // console.log("Product Info: ", history.state.productInfo);
    // this.fetchAddress();
    this.fetchAddressFromCart();
    // this.fetchDataCart();
    this.fetchVouchers();

    if (
      history.state.orderInfo != null ||
      history.state.orderInfo != undefined
    ) {
      // this.isLoading = true;
      this.orderInfo = history.state.orderInfo;
      this.voucherId = history.state.orderInfo[0].voucher_id;
      this.voucherCode = history.state.orderInfo[0].voucher_code;
      this.voucherName = history.state.orderInfo[0].voucher_name;

      for (let i = 0; i < history.state.orderInfo.length; i++) {
        this.productTotalPrice =
          this.productTotalPrice + this.orderInfo[i].subtotal;
        this.overallPrice =
          this.overallPrice + history.state.orderInfo[i].subtotal;
        this.service
          .productDetail(history.state.orderInfo[i].product_id)
          .subscribe((r) => {
            // console.log("Respons Detail produk", r);
            // console.log(r.vouchers, 'vouchss')
            this.productDetails = r;
            this.store_name = r.store!.name;
            this.store_id = r.store!.store_id;

            if (!r.weight || r.weight === 0) {
              r.weight = 1;
            }
            if (!r.length || r.length === 0) {
              r.length = 1;
            }
            if (!r.width || r.width === 0) {
              r.width = 1;
            }
            if (!r.height || r.height === 0) {
              r.height = 1;
            }
            // console.log("fetchProductDetail", r);
            this.service.userAddressDetail(r.store!.store_id).subscribe(() => {
              // console.log(address)
              // this.checkFeeParam = {
              //   originId: r.store!.osas_log_id,
              //   destinationId: null,
              //   weight: this.checkFeeParam.weight + r.weight,
              //   dimension: {
              //     length: this.checkFeeParam.dimension.length + r.length,
              //     width: this.checkFeeParam.dimension.width + r.width,
              //     height: this.checkFeeParam.dimension.height + r.height,
              //   },
              // };
              // console.log(this.checkFeeParam)

              // this.fetchAddress();
              this.isLoading = false;
            });
          });
      }

      this.calcDiscount(this.voucherCode);
    } else {
      // this.routes.navigateByUrl("/cart");
      // this.routes.navigateByUrl("/**")
    }
  }

  // fetchAddress() {
  //   // this.isLoading = true;
  //   this.service.listUserAddress(this.queryTable).subscribe((r: any) => {
  //     if (r.addresses.length > 1) {
  //       for (let i = 0; i < r.addresses.length; i++) {
  //         if (r.addresses[i].primary_address == true) {
  //           this.primaryAddress = r.addresses[i];
  //         }
  //       }
  //       if (this.primaryAddress === null || this.primaryAddress === undefined) {
  //         this.primaryAddress = r.addresses[0];
  //       }
  //     } else {
  //       this.primaryAddress = r.addresses[0];
  //     }
  //     // console.log("selected address id: ", this.selectedAddressId);
  //     if (
  //       this.selectedAddressId === null ||
  //       this.selectedAddressId === undefined
  //     ) {
  //       this.selectedAddressId = this.primaryAddress;
  //     } else {
  //       this.primaryAddress = this.selectedAddressId;
  //     }

  //     this.checkFeeParam = {
  //       originId: history.state.productInfo.store!.osas_log_id,
  //       destinationId: this.primaryAddress.address.id,
  //       weight: this.checkFeeParam.weight,
  //       dimension: {
  //         length: this.checkFeeParam.dimension.length,
  //         width: this.checkFeeParam.dimension.width,
  //         height: this.checkFeeParam.dimension.height,
  //       },
  //     };

  //     this.service.checkDeliveryFee(this.checkFeeParam).subscribe((res) => {
  //       // console.log("Response check fee: ", res);
  //       this.services = [];
  //       res.services.map((service: ServiceData) => {
  //         if (service.total !== 0 && service.serviceId !== 101) {
  //           this.services.push(service);
  //         }
  //       });
  //       // this.services = res.services
  //       this.deliveryPrice = this.services[0].total;
  //       this.selectedServiceId = this.services[0].serviceId;
  //       this.selectedExpedition = this.services[0].name;
  //       // console.log(this.services);
  //       this.overallPrice = this.productTotalPrice + this.deliveryPrice;
  //       this.isLoading = false;
  //     });
  //     // console.log(this.checkFeeParam)
  //   });
  //   // this.reviewInfo.forEach(() => this.insuranceUsedArray.push(false));
  // }

  fetchAddressFromCart() {
    // this.isLoading = true;
    this.service.listUserAddress(this.queryTable).subscribe((r: any) => {
      if (r.addresses.length > 1) {
        for (let i = 0; i < r.addresses.length; i++) {
          if (r.addresses[i].primary_address == true) {
            this.primaryAddress = r.addresses[i];
          }
        }
        if (this.primaryAddress === null || this.primaryAddress === undefined) {
          this.primaryAddress = r.addresses[0];
        }
      } else {
        this.primaryAddress = r.addresses[0];
      }
      // console.log("selected address id: ", this.selectedAddressId);
      if (
        this.selectedAddressId === null ||
        this.selectedAddressId === undefined
      ) {
        this.selectedAddressId = this.primaryAddress;
      } else {
        this.primaryAddress = this.selectedAddressId;
      }
      this.fetchDataCart(this.primaryAddress);
    });
    this.reviewInfo.forEach(() => this.insuranceUsedArray.push(false));
  }

  //NOTE: Logic for cart and now for single also
  fetchDataCart(primaryAddress: any) {
    this.router.queryParamMap.subscribe((params) => {
      this.orderInfo = JSON.parse(atob(params.get("orderInfo")));
      this.cartId = 1;
    });
    console.log(this.orderInfo, "order info");

    const dataPayload = {
      orders: [],
      shipping: {
        address_id: Number(primaryAddress.address_id),
      },
    };

    const checkListObservables = this.orderInfo.map((store) => {
      const checkServicePayload: CheckServicePayload = {
        store_id: store.store_id,
        location_id: this.primaryAddress.address.id,
        // city_id: this.primaryAddress.address.city_id,
        products: store.products.map(
          (product: { product_id: number; quantity: number }) => ({
            product_id: product.product_id,
            quantity: product.quantity,
          })
        ),
      };
      return this.service.checkListService(checkServicePayload).pipe(
        tap((data) => console.log("Service response:", data)),
        map((data: any) => {
          const selectedService = data[0];
          return {
            store_id: store.store_id,
            selectedServiceId: selectedService
              ? selectedService.service_id
              : null,
            products: store.products,
          };
        })
      );
    });

    forkJoin(checkListObservables).subscribe((results) => {
      results.forEach((result) => {
        if (!result.selectedServiceId) {
          console.error(
            `selectedServiceId is undefined for store_id: ${result.store_id}`
          );
        }

        const order = {
          store_id: result.store_id,
          shipping: {
            insurance: this.insuranceUsed,
            service_id: result.selectedServiceId,
          },
          products: result.products.map(
            (product: {
              variant_id: number;
              quantity: number;
              product_id: number;
            }) => ({
              variant_id: product.variant_id,
              quantity: product.quantity,
              product_id: product.product_id,
            })
          ),
        };

        dataPayload.orders.push(order);
        console.log(result.selectedServiceId, "selectedServiceId");
      });

      console.log("data payload all", dataPayload);
      this.service
        .reviewOrder(dataPayload)
        .pipe(
          tap({
            next: (res: any) => {
              if (res.error.length > 0) {
                this.dialog.open(DialogAlert, {
                  width: "300px",
                  data: {
                    status: false,
                    message: res.error[0].message,
                  },
                });
              } else {
                console.log("Tidak ada error");
              }
              console.log(res);
              this.reviewInfo = res.orders;
              this.overallPrice = res.price_data.total_payment;
              this.productTotalPrice = res.price_data.merchant_subtotal;
              this.deliveryPrice = res.price_data.shipping_fee_subtotal;
              this.reviewInfo.forEach((store, index) => {
                this.pickServiceFromCart(index, store, false);
              });
            },
            error: (err) => {
              console.log(err);
            },
          })
        )
        .subscribe();
    });
  }

  getTotalPrice(products: any[]): number {
    return products.reduce((total, product) => total + product.total_price, 0);
  }

  voucherList: any;

  fetchVouchers() {
    // this.isLoading = true
    this.service.voucherList().subscribe(
      (data) => {
        // console.log(data)
        this.voucherList = data;
        // this.isLoading = false
      },
      (e) => {
        console.log(e);
        // this.isLoading = false
      }
    );
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  openDialog(action: string, obj: any) {
    debugger;
    obj.action = action;

    const dialogRef = this.dialog.open(AddAddress, {
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "Tambah") {
        this.addRowData(result.data);
      }
    });
  }

  openSnackBar(message: any, action: any) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  addRowData(row_obj: AddressData) {
    const newAddressData: AddressData = {
      address: row_obj.address,
      recipient: row_obj.recipient,
      phone: "62" + row_obj.phone.substring(1),
      category: row_obj.category,
      province: row_obj.address.province_name,
      district: row_obj.address.sub_district_name,
      post_id: row_obj.address.postal_code.toString(),
      address_input: row_obj.address_input,
      primary_address: row_obj.primary_address,
      city: row_obj.address.city_name,
      urban: row_obj.address.urban_name,
      osas_log_id: row_obj.address.id,
    };

    this.service
      .addUserAddress(newAddressData, localStorage.getItem("jwt"))
      .subscribe(
        () => {
          this.dialog.open(ConfirmAddressComponent);
          // console.log("SUCCESS")
          this.fetchAddressFromCart();
          this.fetchVouchers();
        },
        (e) => {
          console.log(e);
          this.dialog.open(DialogAlert, {
            width: "300px",
            data: {
              status: false,
              message: "Gagal menambah alamat. Mohon coba beberapa saat lagi.",
            },
          });
        }
      );

    // this.fetchAddress();
    this.fetchVouchers();
    this.table.renderRows();
  }

  pageSize: number = 10;
  pageIndex: number = 0;

  queryTable: QueryTable = {
    limit: this.pageSize,
    pageIndex: this.pageIndex,
  };

  selectAddress(address_id: string) {
    // console.log("address ID: ", address_id);
    let dialogRef = this.dialog.open(SelectAddressComponent, {
      width: "750px",
      data: address_id,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.selectedAddressId = result;
      this.fetchAddressFromCart();

      // this.fetchAddress();
      // this.fetchVouchers();
      // this.calcDiscount(this.voucherCode);
    });
  }

  pickServiceFromCart(index: number, data: any, isClicked: boolean) {
    const products = data.products.map(
      (product: { product_id: number; quantity: number }) => ({
        product_id: product.product_id,
        quantity: product.quantity,
      })
    );
    const dataInject = {
      store_id: data.store_id,
      location_id: this.primaryAddress.address.id,
      // city_id: this.primaryAddress.address.city_id,
      products: products,
    };

    if (!isClicked) {
      this.service.checkListService(dataInject).subscribe(
        (res) => {
          this.isLoading = false;
          this.services = res;
          console.log("Daftar service not clicked: ", res);

          let lowestShippingFee = Infinity;
          let selectedService = null;

          this.services.forEach((service) => {
            if (
              service.service_id !== 9 &&
              service.shipping_fee < lowestShippingFee
            ) {
              lowestShippingFee = service.shipping_fee;
              selectedService = service;
            }
          });

          if (!selectedService) {
            // If no service other than "ambil di tempat" is available, select "ambil di tempat"
            selectedService = this.services.find(
              (service) => service.service_id === 9
            );
          }

          const selectedServiceId = selectedService.service_id;
          const selectedServiceName = selectedService.service_name;

          this.selectedExpeditionsPrice[index] = selectedService.shipping_fee;
          this.selectedExpeditions[index] = selectedServiceName;
          this.selectedServiceIds[index] = selectedServiceId;
          this.selectedCodAcitivated[index] = selectedService.is_cod;

          console.log(this.selectedServiceIds, "service yang dipilih");

          const dataPayload = {
            orders: [],
            shipping: {
              address_id: Number(this.primaryAddress.address_id),
            },
          };

          this.orderInfo.forEach((store, storeIndex) => {
            const order = {
              store_id: store.store_id,
              shipping: {
                insurance: this.insuranceUsed,
                service_id: this.selectedServiceIds[storeIndex],
              },
              products: [],
            };

            store.products.forEach(
              (product: {
                variant_id: number;
                quantity: number;
                product_id: number;
              }) => {
                order.products.push({
                  variant_id: product.variant_id,
                  quantity: product.quantity,
                  product_id: product.product_id,
                });
              }
            );

            dataPayload.orders.push(order);
          });

          this.service
            .reviewOrder(dataPayload)
            .pipe(
              tap({
                next: (res: any) => {
                  if (res.error.length > 0) {
                    this.dialog.open(DialogAlert, {
                      width: "300px",
                      data: {
                        status: false,
                        message: res.error[0].message,
                      },
                    });
                  } else {
                    console.log("Tidak ada error");
                  }
                  this.reviewInfo = res.orders;
                  this.overallPrice = res.price_data.total_payment;
                  this.productTotalPrice = res.price_data.merchant_subtotal;
                  this.deliveryPrice = res.price_data.shipping_fee_subtotal;
                },
                error: (err) => {
                  console.log(err);
                },
              })
            )
            .subscribe();
          this.fetchVouchers();
          this.calcDiscount(this.voucherCode);
        },
        (err) => {
          this.isLoading = false;
          this.dialog.open(DialogAlert, {
            width: "300px",
            data: {
              status: false,
              message: err.error.message,
            },
          });
        }
      );
    } else {
      let dialogRef = this.dialog.open(SelectServiceComponent, {
        width: "500px",
        data: dataInject,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result != null && result != undefined) {
          this.selectedExpeditions[index] = result.service_name;
          this.selectedExpeditionsPrice[index] = result.shipping_fee;
          this.selectedServiceIds[index] = result.service_id;
          this.deliveryPrice = result.delivery_price;
          this.selectedCodAcitivated = result.is_cod;
        }

        const dataPayload = {
          orders: [],
          shipping: {
            address_id: Number(this.primaryAddress.address_id),
          },
        };

        this.orderInfo.forEach((store, index) => {
          const order = {
            store_id: store.store_id,
            shipping: {
              insurance: this.insuranceUsed,
              service_id: this.selectedServiceIds[index],
            },
            products: [],
          };

          store.products.forEach(
            (product: {
              variant_id: number;
              quantity: number;
              product_id: number;
            }) => {
              order.products.push({
                variant_id: product.variant_id,
                quantity: product.quantity,
                product_id: product.product_id,
              });
            }
          );

          dataPayload.orders.push(order);
        });

        console.log(dataPayload, "data payload");
        this.service
          .reviewOrder(dataPayload)
          .pipe(
            tap({
              next: (res: any) => {
                // console.log(res);
                if (res.error.length > 0) {
                  this.dialog.open(DialogAlert, {
                    width: "300px",
                    data: {
                      status: false,
                      message: res.error[0].message,
                    },
                  });
                } else {
                  console.log("Tidak ada error");
                }
                this.reviewInfo = res.orders;
                this.overallPrice = res.price_data.total_payment;
                this.productTotalPrice = res.price_data.merchant_subtotal;
                this.deliveryPrice = res.price_data.shipping_fee_subtotal;
              },
              error: (err) => {
                console.log(err);
              },
            })
          )
          .subscribe();

        this.fetchVouchers();
        this.calcDiscount(this.voucherCode);
      });
    }
  }

  pickVoucher() {
    let dialogRef = this.dialog.open(SelectVoucherComponent, {
      width: "550px",
      data: {
        voucher: this.voucherList.vouchers,
        currentPrice: this.productTotalPrice,
        selectedVoucher: this.voucherId,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((voucher) => {
      if (voucher) {
        // console.log(voucher)
        if (voucher.voucher.voucher_id !== this.voucherId) {
          this.calcDiscount(voucher.voucher.code);
        }
        this.voucherCode = voucher.voucher.code;
        this.voucherId = voucher.voucher.voucher_id;
        this.voucherName = voucher.voucherName;
        // this.variantDetails.voucher_id = voucher.data
        this.openSnackBar("Voucher berhasil digunakan", "OK");
      }
    });
    this.fetchVouchers();
  }

  calcDiscount(code: string) {
    if (code) {
      this.isLoading = true;
      this.service.calculateDiscount(code, this.productTotalPrice).subscribe(
        (r) => {
          this.isLoading = false;
          // console.log(r)
          this.discountedPrice = r.price_after_discount + this.deliveryPrice;
        },
        (e) => {
          this.isLoading = false;
          console.log(e);
          this.dialog.open(DialogAlert, {
            width: "300px",
            data: {
              status: false,
              message:
                "Terjadi kesalahan dalam checkout. Mohon coba beberapa saat lagi.",
            },
          });
        }
      );
    }
  }

  checkCheckBoxValueCart(event: any, index: number) {
    // const isChecked = event.checked;
    // console.log("is Checked keberapa :", isChecked);
    // this.insuranceUsedArray[index] = isChecked;
    const dataPayload = {
      orders: [],
      shipping: {
        address_id: Number(this.primaryAddress.address_id),
      },
    };

    this.orderInfo.forEach(() => {
      this.insuranceUsedArray.push(false);
    });

    this.orderInfo.forEach((store, index) => {
      const order = {
        store_id: store.store_id,
        shipping: {
          insurance: this.insuranceUsedArray[index] || false,
          service_id: this.selectedServiceIds[index],
        },
        products: [],
      };
      store.products.forEach(
        (product: {
          variant_id: number;
          quantity: number;
          product_id: number;
        }) => {
          order.products.push({
            variant_id: product.variant_id,
            quantity: product.quantity,
            product_id: product.product_id,
          });
        }
      );
      dataPayload.orders.push(order);
    });

    console.log(dataPayload, "data payload");
    this.service
      .reviewOrder(dataPayload)
      .pipe(
        tap({
          next: (res: any) => {
            if (res.error.length > 0) {
              this.dialog.open(DialogAlert, {
                width: "300px",
                data: {
                  status: false,
                  message: res.error[0].message,
                },
              });
            } else {
              console.log("Tidak ada error");
            }
            console.log(res);
            this.reviewInfo = res.orders;
            this.overallPrice = res.price_data.total_payment;
            this.insuranceFee = res.price_data.insurance_subtotal;
          },
          error: (err) => {
            console.log(err);
          },
        })
      )
      .subscribe();
  }

  checkout() {
    // console.log("info order Checkout", this.orderInfo);

    var orderSummary = {};
    this.isLoading = true;

    if (this.orderInfo[0].cart_detect == true) {
      orderSummary = {
        orders: [],
        shipping: {
          address_id: Number(this.primaryAddress.address_id),
        },
        payment: this.form.value.payment,
      };

      this.orderInfo.forEach((store, index) => {
        const order = {
          store_id: store.store_id,
          shipping: {
            insurance: this.insuranceUsedArray[index] || false,
            service_id: this.selectedServiceIds[index],
          },
          products: [],
          buyer_note: this.notesArray[index],
        };

        store.products.forEach(
          (product: {
            cart_id: number | null;
            variant_id: number;
            quantity: number;
            product_id: number;
          }) => {
            order.products.push({
              cart_id: product.cart_id,
              variant_id: product.variant_id,
              quantity: product.quantity,
              product_id: product.product_id,
            });
          }
        );

        (orderSummary as { orders: any[] }).orders.push(order);
      });
    } else {
      if (this.selectedAddressId && this.selectedAddressId.address_id) {
        this.isLoading = false;
      } else {
        this.isLoading = false;
        Swal.fire({
          title: "Alamat belum dipilih",
          text: "Mohon pilih alamat terlebih dahulu",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }

      orderSummary = {
        store_name: this.store_name,
        shipping: {
          address_id: Number(this.selectedAddressId.address_id)!,
        },
        payment: this.form.value.payment,
        orders: [
          {
            store_id: Number(this.productDetails.store!.store_id),
            buyer_note: this.form.value.notes,
            shipping: {
              insurance: this.insuranceUsed,
              service_id: this.selectedServiceId,
              // delivery_fee: this.deliveryPrice,
              // overal_price: this.overallPrice,
              // insurance_fee: this.insuranceFee,
              // voucher: this.voucherCode,
            },
            products: [
              {
                cart_id: null,
                variant_id: Number(this.orderInfo[0].variant_id),
                quantity: Number(this.orderInfo[0].quantity),
                product_id: Number(this.orderInfo[0].product_id),
              },
            ],
          },
        ],
      };
    }

    // console.log("ordre sum: ", orderSummary);

    // orderSummary.order_info = this.orderInfo
    if (
      this.voucherCode !== null &&
      this.voucherCode !== "" &&
      this.voucherCode !== undefined
    ) {
      if (this.form.value.payment === "COD") {
        this.service.redeemVoucher(this.voucherCode).subscribe(
          () => {
            this.isLoading = false;
            this.service.xenditCheckout(orderSummary).subscribe(
              (data) => {
                this.isLoading = false;
                // console.log("response cod", data);
                this.routes.navigateByUrl("/user/order", {
                  state: {
                    orderSummary: orderSummary,
                    invoice: data.invoice,
                    payment_url: data.payment_url,
                  },
                });
              },
              (e) => {
                this.isLoading = false;
                console.log(e);
                if (e.error.message === "payment")
                  this.openSnackBar("Checkout gagal. " + e.error.message, "");
              }
            );
          },
          (e) => {
            this.isLoading = false;
            console.log(e);
            this.openSnackBar("Checkout gagal. " + e.error.message, "");
          }
        );
      } else {
        this.service.xenditCheckout(orderSummary).subscribe(
          (data) => {
            this.isLoading = false;
            // console.log("response cod else", data);
            this.routes.navigateByUrl("/checkout-finish", {
              state: {
                orderSummary: orderSummary,
                invoice: data.invoice,
                payment_url: data.payment_url,
              },
            });
          },
          (e) => {
            this.isLoading = false;
            if (
              e.error.message ===
              "'payment' must be equal to one of the allowed values COD,EWallet"
            ) {
              this.openSnackBar(
                "Checkout gagal. Harus pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else if (e.error.message === "") {
            }
            console.log(e);
          }
        );
      }
    } else {
      if (this.form.value.payment === "COD") {
        console.log(orderSummary, "order summary COD");
        this.service.xenditCheckout(orderSummary).subscribe(
          (data) => {
            this.isLoading = false;
            // console.log("response else cod: ", data);
            this.routes.navigateByUrl("/user/order", {
              state: {
                orderSummary: orderSummary,
                invoice: data.invoice,
                payment_url: data.payment_url,
              },
            });
          },
          (e) => {
            this.isLoading = false;
            if (
              e.error.message ===
              "'payment' must be equal to one of the allowed values COD,EWallet"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else if (
              e.error.message ===
              "Must have required property 'orders.0.shipping.service_id'"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih service pengiriman terlebih dahulu",
                ""
              );
            } else {
              this.openSnackBar(
                "Checkout gagal. Mohon coba beberapa saat lagi",
                ""
              );
            }
          }
        );
      } else {
        console.log("order Summary e-wallet", orderSummary);
        this.service.xenditCheckout(orderSummary).subscribe(
          (data) => {
            this.isLoading = false;
            // console.log("response else e-wallet: ", data);
            // const orderSummary = {
            //   store_name: this.store_name,
            //   shipping: {
            //     address_id: Number(this.selectedAddressId.address_id)!,
            //   },
            //   payment: this.form.value.payment,
            //   orders: [
            //     {
            //       store_id: Number(this.productDetails.store!.store_id),
            //       buyer_note: this.form.value.notes,
            //       shipping: {
            //         insurance: this.insuranceUsed,
            //         service_id: this.selectedServiceId,
            //         delivery_fee: this.deliveryPrice,
            //         overal_price: this.overallPrice,
            //         insurance_fee: this.insuranceFee,
            //         // voucher: this.voucherCode,
            //       },
            //       products: this.orderInfo,
            //     },
            //   ],
            // };

            // const queryParams = {
            //   orderSummary: orderSummary,
            //   payment_url: data.payment_url,
            //   invoice: data.payment_id,
            //   amount: data.amount,
            //   payment_id: data.payment_id,
            // };

            // this.routes.navigate(["/checkout-finish"], {
            //   queryParams: {
            //     orderSummary: btoa(JSON.stringify(queryParams)),
            //   },
            // });
            window.open(data.payment_url, "_blank");
            setTimeout(() => {
              this.routes.navigate(["/user/order"]);
            }, 1000);
          },
          (e) => {
            this.isLoading = false;
            if (
              e.error.message ===
              "'payment' must be equal to one of the allowed values COD,EWallet"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else if (
              e.error.message ===
              "Must have required property 'orders.0.shipping.service_id'"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih service pengiriman terlebih dahulu",
                ""
              );
            } else if (e.error.message === "'payment' can not be empty") {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else {
              this.openSnackBar(
                "Checkout gagal. Mohon coba beberapa saat lagi",
                ""
              );
            }
          }
        );
      }
    }
  }
}
