import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  @ViewChild("slider") slider: ElementRef;
  imagesPartner: string[] = [
    "assets/img/our-partner/01-xendit_logo.png",
    "assets/img/our-partner/Anteraja.png",
    "assets/img/our-partner/JNE.png",
    "assets/img/our-partner/POS.png",
    "assets/img/our-partner/malacca-01 1.png"

  ];

  imagesSeller: string[] = [
    "assets/img/our-partner/sampoerna.png",
    "assets/img/our-partner/gigas.png",
    "assets/img/our-partner/nojorono.png",
    "assets/img/our-partner/impraboard.png",
  ]

  reviews = [
    {
      title: "",
      text: "Penjualan saya meningkat secara signifikan sejak bergabung OnMarket. Selain fee seller yang sangat rendah,juga memberikan kesempatan untuk mempromosikan toko atau produk saya melalui influencer secara gratis.",
      image: "assets/img/avatar-profile.png",
      name: "Iin Dwi",
      position: "Hijab Kekinian",
    },
    {
      title: "",
      text: "Awal nya saya ragu karena baru pertama kali mendengar OnMarket, namun pada saat saya cek website dan fiturnya, jujur saya sangat lega dan antusias karena dashboardnya mudah digunakan dan yang terpenting fee 1% sangat rendah benar-benar membantu meningkatkan profit bisnis saya.",
      image: "assets/img/avatar-profile.png",
      name: "Michael Vincent",
      position: "Classmild Distributor",
    },
    {
      title: "",
      text: "Terimakasih OnMarket, berkat OnMarket saya yang agak gaptek ini lebih semangat untuk jualan online karena fiturnya sangat simple dan mudah dipahami. Proses pencairan dananya juga gak ribet hanya 2x24 jam. Sukses terus OnMarket!",
      image: "assets/img/avatar-profile.png",
      name: "Sanny Clarista",
      position: "Kantin Creative",
    },
    {
      title: "",
      text: "Bergabung dengan OnMarket membantu menjangkau customer lebih luas dan yang paling menarik pilihan jasa pengirimannya lengkap dan banyak subsidi hingga free ongkir.",
      image: "assets/img/avatar-profile.png",
      name: "Imam Mahmudi",
      position: "GT Radial Distributor",
    },
    {
      title: "",
      text: "Yang paling unik dari OnMarket yaitu layanan customer support yang responsif memudahkan saya dalam mengatasi setiap kendala. Feel-nya seperti sedang kordinasi pekerjaan dengan timwork sendiri. Semoga OnMarket terus berkembang dan meningkatkan aplikasinya.",
      image: "assets/img/avatar-profile.png",
      name: "Zavyka",
      position: "Zavyka official",
    },
    {
      title: "",
      text: "Saya sangat puas berjualan di ONMARKET! Platform ini sangat user-friendly dan memudahkan saya untuk mengelola produk dan pesanan. Saya sangat merekomendasikan ONMARKET bagi siapa pun yang ingin meningkatkan bisnis onlinenya!",
      image: "assets/img/avatar-profile.png",
      name: "Reynaldi Pratama",
      position: "On Cafe",
    },

  ];
  constructor() { }

  ngOnInit(): void { }
  ngAfterViewInit(): void {
    const slider = this.slider.nativeElement as HTMLElement;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;
    });
    const shadowLeft = document.createElement("div");
    const shadowRight = document.createElement("div");
    shadowLeft.className = "shadow-left";
    shadowRight.className = "shadow-right";
    slider.appendChild(shadowLeft);
    slider.appendChild(shadowRight);

    const updateShadows = () => {
      const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
      if (slider.scrollLeft > 0) {
        shadowLeft.style.opacity = "1";
      } else {
        shadowLeft.style.opacity = "0";
      }
      if (slider.scrollLeft < maxScrollLeft) {
        shadowRight.style.opacity = "1";
      } else {
        shadowRight.style.opacity = "0";
      }
    };

    slider.addEventListener("scroll", updateShadows);
    updateShadows();
  }
}
