import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NumberFormatPipe } from "../../number.pipe";
import { FormBuilder, FormGroup } from "@angular/forms";

interface FilterObj {
  price?: {
    min_price?: number;
    max_price?: number;
  };

  option?: string;
  location?: string;
  condition?: string;
}

interface QueryTable {
  // sort: null | Object;
  filters: FilterObj;
  page: number;
  limit: number;
  shop_category?: number;
  category_id?: number;
  store_id?: number;
  // promo?: null | boolean;
}

@Component({
  selector: "app-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
  providers: [NumberFormatPipe],
})
export class SearchResultComponent implements OnInit {
  products: any[];
  stores: any[];
  pageIndex: number = 0;
  limitSize: number = 20;
  totalPageSize: any[] = [];
  params: ParamMap;
  form: FormGroup;
  option: string = "";

  constructor(
    private service: ApiService,
    private route: ActivatedRoute,
    private routes: Router,
    private currencyFormat: NumberFormatPipe,
    private formBuilder: FormBuilder
  ) {
    this.params = this.route.snapshot.queryParamMap;
    this.pageIndex = parseInt(this.params.get("page")) - 1;
    this.fetchProducts();
    this.fetchStoreList();

    this.form = this.formBuilder.group({
      minPrice: [null],
      maxPrice: [null],
    });

    // this.routes.navigate(["/search"], {
    //   queryParams: {
    //     keyword: this.params.get("keyword"),
    //     page: this.pageIndex + 1,
    //   },
    //   replaceUrl: true,
    // });
    // .then(() => {
    //   window.location.reload()
    // })
  }

  // get loopPagination() {
  //   var Arr = Array(this.totalPageSize + 2).fill(0).map((x,i)=>i)
  //   return Arr
  // }

  minPrice: string = null;
  maxPrice: string = null;
  ngOnInit(): void {
    // if (localStorage.getItem("refreshed") === null) {
    //   localStorage["refreshed"] = true;
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem("refreshed");
    // }
  }

  get minsPrice() {
    return this.minPrice;
  }
  set minsPrice(v) {
    this.minPrice = v !== null ? "Rp" + v?.replace(/[^0-9.]/g, "") : null;
  }

  get maxsPrice() {
    return this.maxPrice;
  }
  set maxsPrice(v) {
    this.maxPrice = v !== null ? "Rp" + v?.replace(/[^0-9.]/g, "") : null;
  }

  onMinChange(event: any) {
    if (!/^\d+$/.test(event)) {
      event = event.replace(/\D/g, "");
    }

    this.minPrice = "Rp " + this.priceTransform(event);
  }

  onMaxChange(event: any) {
    if (!/^\d+$/.test(event)) {
      event = event.replace(/\D/g, "");
    }

    this.maxPrice = "Rp " + this.priceTransform(event);
  }

  queryTable: QueryTable = {
    filters: {},
    page: this.pageIndex,
    limit: this.limitSize,
  };

  // selectCheckbox(event: any) {
  //   this.queryTable.promo = event.target.checked;
  //   this.fetchProducts();
  // }

  // onQueryParamsChange(params): void {
  //   const { pageSize, pageIndex, sort, filter } = params
  //   this.queryTable.pageIndex = this.pageIndex
  //   this.queryTable.promo = this.checkbox
  //   const currentSort = sort.find(item => item.value !== null)
  //   const sortField = (currentSort && currentSort.key) || null
  //   const sortOrder = (currentSort && currentSort.value) || null

  //   if (sortField != null && sortOrder != null) {
  //     this.queryTable.sort = {
  //       field: sortField,
  //       order: sortOrder,
  //     }
  //   } else {
  //     this.queryTable.sort = null
  //   }
  //   this.fetchProducts()
  // }

  search() {
    this.queryTable.filters = {
      price: {
        min_price:
          this.form.value.minPrice !== null &&
            this.form.value.minPrice !== "" &&
            this.form.value.minPrice !== undefined &&
            this.form.value.minPrice.length > 3
            ? Number(this.form.value.minPrice.replace(/[^\d]/g, ""))
            : null,
        max_price:
          this.form.value.maxPrice !== null &&
            this.form.value.maxPrice !== "" &&
            this.form.value.maxPrice !== undefined &&
            this.form.value.maxPrice.length > 3
            ? Number(this.form.value.maxPrice.replace(/[^\d]/g, ""))
            : null,
      },

      option: this.option,
    };

    if (this.option == "refresh") {
      this.form.controls["minPrice"].setValue(null);
      this.form.controls["maxPrice"].setValue(null);
      this.option = "";
    }
    // console.log(this.queryTable.filters)
    this.fetchProducts();
    this.fetchStoreList();
  }

  minPriceFilter(event: any) {
    // console.log(event.target.value)

    if (event.target.value?.length > 0) {
      this.queryTable.filters = {
        price: {
          min_price:
            this.form.value.minPrice !== null &&
              this.form.value.minPrice !== "" &&
              this.form.value.minPrice !== undefined &&
              this.form.value.minPrice.length > 3
              ? Number(this.form.value.minPrice.replace(/[^\d]/g, ""))
              : null,
          max_price:
            this.form.value.maxPrice !== null &&
              this.form.value.maxPrice !== "" &&
              this.form.value.maxPrice !== undefined &&
              this.form.value.maxPrice.length > 3
              ? Number(this.form.value.maxPrice.replace(/[^\d]/g, ""))
              : null,
        },

        // condition: this
        option: this.option,
      };

      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = {};
    }
  }

  maxPriceFilter(event: any) {
    // console.log(event.target.value)

    if (event.target.value?.length > 0) {
      this.queryTable.filters = {
        price: {
          min_price:
            this.form.value.minPrice !== null &&
              this.form.value.minPrice !== "" &&
              this.form.value.minPrice !== undefined &&
              this.form.value.minPrice.length > 3
              ? Number(this.form.value.minPrice.replace(/[^\d]/g, ""))
              : null,
          max_price:
            this.form.value.maxPrice !== null &&
              this.form.value.maxPrice !== "" &&
              this.form.value.maxPrice !== undefined &&
              this.form.value.maxPrice.length > 3
              ? Number(this.form.value.maxPrice.replace(/[^\d]/g, ""))
              : null,
        },
        option: this.option,
      };

      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = {};
    }
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === "previous") {
      this.pageIndex = Math.max(0, this.pageIndex - 1);
    } else if (arg === "next") {
      this.pageIndex = Math.min(
        this.totalPageSize.length - 1,
        this.pageIndex + 1
      );
    }
    this.queryTable.page = this.pageIndex;
    this.fetchProducts();
  }

  numberPagination(index: number = 0) {
    this.pageIndex = index;
    this.queryTable.page = this.pageIndex;
    this.fetchProducts();
  }

  fetchStoreList() {
    var keyword = this.params.get("keyword");
    this.service.searchStores(this.queryTable, keyword).subscribe(
      (data) => {
        this.stores = data.stores;
        console.log("data stores: ", this.stores);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fetchProducts() {
    var keyword = this.params.get("keyword");
    this.service.searchProducts(this.queryTable, keyword).subscribe(
      (data) => {
        this.products = data.products;
        this.totalPageSize = Array(Math.ceil(data.total / 24));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  brokenStoreImage(storeIndex) {
    this.stores[storeIndex].image_url = 'assets/img/store.png'
  }

  brokenStoreProductImage(storeIndex, productIndex) {
    this.stores[storeIndex].related_products[productIndex].main_photo_url = 'assets/img/broken-image.png'
  }

  brokenProductImage(productIndex) {
    this.products[productIndex].main_photo_url = 'assets/img/broken-image.png'
  }
}
