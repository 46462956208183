<div class="blog" style="overflow-x: hidden">
  <div class="main circle-background">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <app-main-header></app-main-header>
    <app-breadcrumb-faq></app-breadcrumb-faq>
    <section class="section faq-area ptb_50">
      <div class="container">
        <h1 style="text-align: center" class="ptb_100">
          Temukan jawaban dari berbagai pertanyaan yang sering diajukan
        </h1>
        <h2>Tentang ONMARKET</h2>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Apa itu ONMARKET?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                <b>ONMARKET</b> adalah Platform jual-beli online asal Indonesia yang terintegrasi pada <b>Sistem
                  Manajemen
                  Pintar Saas</b> yang
                menyediakan solusi lengkap toko online melalui Omi Storefront, Omi Pos (coming soon), dan Omi Web
                (coming
                soon) yang
                bekerja dibawah induk perusahaan <b>ONINDONESIA</b>.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Registrasi Akun ONMARKET
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat justo et posuere aliquet.
                Mauris
                sed laoreet
                libero. Mauris convallis cursus leo, sit amet finibus eros laoreet porta. Nam cursus lectus eu luctus
                tempus. Cras
                aliquet porttitor mi at lobortis. Donec non tempor nisi. Aenean tempus eros et libero mollis sodales.
                Phasellus faucibus
                leo quis orci porttitor, nec ultrices nisi dapibus. Fusce egestas, lacus sit amet suscipit venenatis,
                lectus nisi
                malesuada neque, eget efficitur quam diam eu lectus. Interdum et malesuada fames ac ante ipsum primis in
                faucibus..
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <h2 class="pt_50">OMI Storefront</h2>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Apa itu Omi Storefront?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                <b>Omi Storefront</b> adalah platform <strong>"toko mandiri"</strong> yang menawarkan antarmuka pengguna
                mirip marketplace, tetapi dengan
                pendekatan yang berbeda. Di sini, para penjual fokus pada manajemen toko dan produk mereka sendiri.
                <strong>Omi Storefront</strong> tidak
                menampilkan produk secara publik kepada pelanggan, melainkan memberikan kontrol penuh kepada penjual
                dalam menjalankan
                bisnis.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Bagaimana cara kerjanya?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                <ul>
                  <li>&bull; Produk pada storefront sudah siap oleh Seller</li>
                  <li>&bull; Pastikan katalog produk mu menarik customer</li>
                  <li>&bull; Salin link storefront-mu atau produk dan letakkan pada Bio media sosialmu atau linktree
                  </li>
                  <li>&bull; Customer meng-klik link tersebut lalu diarahkan menuju halaman produk atau toko</li>
                  <li>&bull; Customer melakukan pesanan online, memilih jasa pengiriman, dan metode pembayaran</li>
                  <li>&bull; Checkout & pembayaran berhasil dilakukan</li>
                  <li>&bull; Seller menerima pesanan online & menyiapkan pesanan dengan baik.</li>
                </ul>
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Perbandingan Omi Storefront dengan marketplace lain?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Omi Storefront</th>
                      <th>Marketplace Lain</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Biaya Pendaftaran</td>
                      <td>Gratis</td>
                      <td>Gratis</td>
                    </tr>
                    <tr>
                      <td>Biaya Transaksi</td>
                      <td>Mulai dari 1%</td>
                      <td>4-12%</td>
                    </tr>
                    <tr>
                      <td>Pencairan Dana</td>
                      <td>2x24jam setelah pesanan diterima</td>
                      <td>4-12%</td>
                    </tr>
                    <tr>
                      <td>Harga Produk</td>
                      <td>Tidak ada perang harga</td>
                      <td>Persaingan harga ketat</td>
                    </tr>
                    <tr>
                      <td>Custom domain</td>
                      <td>&#10003;</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>COD</td>
                      <td>&#10003;</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Optimalkan Penjualan di Tiktok dengan Omi Storefront
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Karena Omi Storefront sudah terintegrasi dengan pembayaran & pengiriman sehingga memudahkan kamu
                membagikan link produk
                atau toko di akun TikTok shop-mu untuk menjangkau lebih banyak pembeli potensial.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Registrasi akun seller Omi Storefront
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat justo et posuere aliquet.
                Mauris
                sed laoreet
                libero. Mauris convallis cursus leo, sit amet finibus eros laoreet porta. Nam cursus lectus eu luctus
                tempus. Cras
                aliquet porttitor mi at lobortis. Donec non tempor nisi. Aenean tempus eros et libero mollis sodales.
                Phasellus faucibus
                leo quis orci porttitor, nec ultrices nisi dapibus. Fusce egestas, lacus sit amet suscipit venenatis,
                lectus nisi
                malesuada neque, eget efficitur quam diam eu lectus. Interdum et malesuada fames ac ante ipsum primis in
                faucibus..
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Jasa pengiriman yang tersedia di Omi Storefront
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                OMI Storefront bekerja sama dengan partner ekspedisi terpercaya seperti OnDelivery, Gosend, JNE, dan
                Anteraja yang
                menawarkan layanan Instant hingga Cargo. Kami memastikan bahwa setiap produk dapat diandalkan untuk
                sampai tepat waktu
                kepada pelanggan Anda.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Biaya Platform Omi Storefront
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Kami mengenakan biaya platform mulai dari 1% dari setiap transaksi berhasil yang dilakukan Pembeli.
                Biaya tersebut sudah
                termasuk biaya jasa payment gateway.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Bagaimana cara menyebarkan Storefront-mu di media sosial?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Kami mengenakan biaya platform mulai dari 1% dari setiap transaksi berhasil yang dilakukan Pembeli.
                Biaya tersebut sudah
                termasuk biaya jasa payment gateway.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Bagaimana cara menghubungi Customer Care?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Kami siap mendengar segala pertanyaan atau masalah yang Anda alami terkait OMI Storefront. Masukan dan
                saran Anda sangat
                berharga bagi kami untuk terus meningkatkan layanan kami sehingga dapat lebih baik lagi dalam memenuhi
                kebutuhan Anda.
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Ikuti Instagram Kami untuk Dapatkan Informasi Ter-update secara Langsung
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Ikuti Instagram @onmarketid untuk mendapatkan informasi terbaru secara langsung mengenai produk, promo,
                dan berita
                terbaru seputar kami. Ikuti Instagram ONMARKET dengan mengakses link 
                <a href="https://www.instagram.com/onmarketid/?hl=en"
                  target="_blank">https://www.instagram.com/onmarketid/?hl=en</a>
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <h2 class="pt_50">Mengelola Storefront</h2>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Bagaimana cara aktivasi Omi Storefront
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">

              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="my-3">
          <mat-accordion>
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 22px; font-weight: 600">
                  Misi Khusus Seller Omi Storefront
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span style="text-align: justify; font-size: 16px;color:#6B7280;line-height: 24px;">
                Para penjual baru yang bergabung dengan Omi Storefront diberikan misi khusus untuk menyelesaikan semua
                instruksi yang
                tersedia di dashboard penjual dalam waktu maksimal 31 hari sejak registrasi akun mereka di Omi
                Storefront.
              </span>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th style=" text-align: center;">Misi</th>
                      <th>Reward</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style=" text-align: left;">Upload Produk</td>
                      <td style=" text-align: left;">5 produk = Voucher Rp 5.000 untuk 5 pembeli<br>10 produk = Voucher
                        Rp 5.000 untuk 10
                        pembeli<br>Berlaku
                        kelipatan, maksimal, 50 produk = Voucher Rp 5.000 untuk 50 pembeli</td>
                    </tr>
                    <tr>
                      <td style=" text-align: left;">Aktifkan Toko</td>
                      <td style=" text-align: left;">Voucher Rp 5.000 untuk 3 pembeli</td>
                    </tr>
                    <tr>
                      <td style=" text-align: left;">Dapatkan Transaksi</td>
                      <td style=" text-align: left;">Kumpulkan point reward yang dapat ditukarkan menjadi hadiah</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </section>
    <div class="pt_50"></div>
    <app-footer-one></app-footer-one>
  </div>
</div>