import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "./components/homepage/homepage.component";
import { SearchResultComponent } from "./components/search-result/search-result.component";
import { ProductDetailsComponent } from "./components/product-details/product-details.component";
import { PersonalStoreComponent } from "./components/personal-store/personal-store.component";
import { CartComponent } from "./components/cart/cart.component";

import { ThemeOneComponent } from "./themes/theme-one/theme-one.component";
import { ThemeTwoComponent } from "./themes/theme-two/theme-two.component";
import { ThemeThreeComponent } from "./themes/theme-three/theme-three.component";
import { ThemeFourComponent } from "./themes/theme-four/theme-four.component";
import { ThemeFiveComponent } from "./themes/theme-five/theme-five.component";
import { ThemeSixComponent } from "./themes/theme-six/theme-six.component";
import { PricingComponent } from "./components/inner-pages/pricing/pricing.component";
import { DownloadPageComponent } from "./components/inner-pages/download-page/download-page.component";
import { SubscribePageComponent } from "./components/inner-pages/subscribe-page/subscribe-page.component";
import { ThankYouComponent } from "./components/inner-pages/thank-you/thank-you.component";
import { ComingSoonComponent } from "./components/inner-pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./components/inner-pages/error/error.component";
import { BlogTwoColumnComponent } from "./components/blogs/blog-two-column/blog-two-column.component";
import { BlogThreeColumnComponent } from "./components/blogs/blog-three-column/blog-three-column.component";
import { BlogLeftSidebarComponent } from "./components/blogs/blog-left-sidebar/blog-left-sidebar.component";
import { BlogRightSidebarComponent } from "./components/blogs/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsLeftSidebarComponent } from "./components/blogs/blog-details-left-sidebar/blog-details-left-sidebar.component";
import { BlogDetailsRightSidebarComponent } from "./components/blogs/blog-details-right-sidebar/blog-details-right-sidebar.component";
import { LoginComponent } from "./components/accounts/login/login.component";
import { SignupComponent } from "./components/accounts/signup/signup.component";
import { ResetComponent } from "./components/accounts/reset/reset.component";
import { ReviewPageComponent } from "./components/inner-pages/review-page/review-page.component";
import { FaqPageComponent } from "./components/inner-pages/faq-page/faq-page.component";
import { ContactPageComponent } from "./components/inner-pages/contact-page/contact-page.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { HelpComponent } from "./components/help/help.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { CheckoutConfirmationComponent } from "./components/checkout-confirmation/checkout-confirmation.component";
import { ProfileComponent } from "./components/accounts/profile/profile.component";
import { MyProfileComponent } from "./components/accounts/profile/myprofile/myprofile.component";
import { OrderComponent } from "./components/accounts/profile/order/order.component";
import { AddressComponent } from "./components/accounts/profile/myprofile/address/address.component";
import { DetailProfileComponent } from "./components/accounts/profile/myprofile/detail-profile/detail-profile.component";
import { PaymentComponent } from "./components/accounts/profile/myprofile/payment/payment.component";
import { AllBillsComponent } from "./components/all-bills/all-bills.component";
import { HomepageDemoComponent } from "./components/main-header-demo/homepage-demo.component";
import { PulsaComponent } from "./components/all-bills/pulsa/pulsa.component";
import { PaketDataComponent } from "./components/all-bills/paket-data/paket-data.component";
import { PlnComponent } from "./components/all-bills/pln/pln.component";
import { GameComponent } from "./components/all-bills/game/game.component";
import { BpjsComponent } from "./components/all-bills/bpjs/bpjs.component";
import { PdamComponent } from "./components/all-bills/pdam/pdam.component";
import { HiburanComponent } from "./components/all-bills/hiburan/hiburan.component";
import { TiketKeretaComponent } from "./components/all-bills/tiket-kereta/tiket-kereta.component";
import { TiketPesawatComponent } from "./components/all-bills/tiket-pesawat/tiket-pesawat.component";
import { SamsatComponent } from "./components/all-bills/samsat/samsat.component";
import { CampaignTemplateComponent } from "./components/campaign-template/campaign-template.component";
import { AuthGuard } from "./auth.guard";
import { CategoryProductComponent } from "./components/category-product/category-product.component";
import { StoreProfileComponent } from "./components/personal-store/store-profile/store-profile.component";
import { ReviewComponent } from "./components/personal-store/review/review.component";
import { ProductComponent } from "./components/personal-store/product/product.component";
import { VerifyPhoneOtpComponent } from "./components/accounts/signup/verify-phone-otp/verify-phone-otp.component";
import { ChangePasswordsComponent } from "./components/accounts/profile/myprofile/change-passwords/change-passwords.component";
import { FaqOneComponent } from "./components/faq/faq-one/faq-one.component";
import { FaqTwoComponent } from "./components/faq/faq-two/faq-two.component";
import { ChatComponent } from "./components/accounts/profile/myprofile/chat/chat.component";
import { FavoriteComponent } from "./components/accounts/profile/myprofile/favorite/favorite.component";

export const AppRoutes: Routes = [
  { path: "", component: HomePageComponent },
  { path: "search", component: SearchResultComponent },
  { path: "product", component: ProductDetailsComponent },
  {
    path: "store",
    component: PersonalStoreComponent,
    children: [
      {
        path: "",
        redirectTo: "/store/",
        pathMatch: "full",
      },
      {
        path: "store-profile",
        component: StoreProfileComponent,
      },
      {
        path: "store-review",
        component: ReviewComponent,
      },
      {
        path: "store-product",
        component: ProductComponent,
      },
    ],
  },
  { path: "cart", canActivate: [AuthGuard], component: CartComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "help", component: HelpComponent },
  { path: "checkout", canActivate: [AuthGuard], component: CheckoutComponent },
  {
    path: "checkout-finish",
    canActivate: [AuthGuard],
    component: CheckoutConfirmationComponent,
  },
  // { path: 'demo', component: HomepageDemoComponent },
  { path: "campaign", component: CampaignTemplateComponent },

  // {
  //   path: "all-bills",
  //   component: AllBillsComponent,
  //   children: [
  //     {
  //       path: "",
  //       redirectTo: "/all-bills/pulsa",
  //       pathMatch: "full",
  //     },
  //     {
  //       path: "pulsa",
  //       component: PulsaComponent,
  //     },
  //     {
  //       path: "paket-data",
  //       component: PaketDataComponent,
  //     },
  //     {
  //       path: "pln",
  //       component: PlnComponent,
  //     },
  //     {
  //       path: "game",
  //       component: GameComponent,
  //     },
  //     {
  //       path: "bpjs",
  //       component: BpjsComponent,
  //     },
  //     {
  //       path: "pdam",
  //       component: PdamComponent,
  //     },
  //     {
  //       path: "hiburan",
  //       component: HiburanComponent,
  //     },
  //     {
  //       path: "tiket-kereta",
  //       component: TiketKeretaComponent,
  //     },
  //     {
  //       path: "tiket-pesawat",
  //       component: TiketPesawatComponent,
  //     },
  //     {
  //       path: "samsat",
  //       component: SamsatComponent,
  //     },
  //   ],
  // },

  {
    path: "user",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "",
        redirectTo: "/user/profile/detail-profile",
        pathMatch: "full",
      },
      {
        path: "profile",
        component: MyProfileComponent,
        children: [
          {
            path: "",
            redirectTo: "/user/profile/detail-profile",
            pathMatch: "full",
          },
          {
            path: "detail-profile",
            component: DetailProfileComponent,
          },
          {
            path: "address",
            component: AddressComponent,
          },
          // {
          //   path: "payment",
          //   component: PaymentComponent,
          // },
        ],
      },
      {
        path: "order",
        component: OrderComponent,
      },
      {
        path: "change-password",
        component: ChangePasswordsComponent,
      },
      {
        path: "chats",
        component: ChatComponent,
      },
      {
        path: "favorite",
        component: FavoriteComponent,
      },
    ],
  },

  { path: "theme-one", component: ThemeOneComponent },
  { path: "theme-two", component: ThemeTwoComponent },
  { path: "theme-three", component: ThemeThreeComponent },
  { path: "theme-four", component: ThemeFourComponent },
  { path: "theme-five", component: ThemeFiveComponent },
  { path: "theme-six", component: ThemeSixComponent },
  { path: "pricing", component: PricingComponent },
  { path: "download", component: DownloadPageComponent },
  { path: "subscribe", component: SubscribePageComponent },
  { path: "thank-you", component: ThankYouComponent },
  { path: "coming-soon", component: ComingSoonComponent },
  { path: "error", component: ErrorComponent },
  { path: "blog-two-column", component: BlogTwoColumnComponent },
  { path: "blog-three-column", component: BlogThreeColumnComponent },
  { path: "blog-left-sidebar", component: BlogLeftSidebarComponent },
  { path: "blog-right-sidebar", component: BlogRightSidebarComponent },
  {
    path: "blog-details-left-sidebar",
    component: BlogDetailsLeftSidebarComponent,
  },
  {
    path: "blog-details-right-sidebar",
    component: BlogDetailsRightSidebarComponent,
  },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SignupComponent },
  { path: "signup/otp", component: VerifyPhoneOtpComponent },
  { path: "reset", component: ResetComponent },
  { path: "reviews", component: ReviewPageComponent },
  { path: "faq", component: FaqTwoComponent },
  { path: "contact", component: ContactPageComponent },
  { path: "category", component: CategoryProductComponent },

  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full",
  },
];
