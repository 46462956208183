<section class="counter-area ptb_50">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-8">
      <div class="section-heading text-center">
        <h2 class="mb-5">Tentang Kami</h2>
        <h3 class="text-left">Kami Baru Mulai</h3>
        <p class="about-desc mt-3 text-gray text-left">
          Di tengah meningkatnya persaingan penjualan pada marketplace atau bisnis digital, ditambah lagi fee tinggi
          yang di-charge ke Pelaku Bisnis / UMKM.
        </p>
        <p class="text-left about-desc">Itulah yang mendorong kami bertekad mendukung para pemilik bisnis online dalam
          meningkatkan bisnisnya.</p>

        <h4 class="text-left my-5">Berdiri sejak Januari 2024</h4>
        <p class="text-left about-desc">
          <b>ONMARKET</b> adalah Platform jual-beli online asal Indonesia yang terintegrasi pada <b>Sistem Manajemen
            Pintar Saas</b> yang menyediakan solusi lengkap toko online melalui Omi Storefront, Omi Pos, dan Omi Web
          yang bekerja dibawah induk
          perusahaan <b>ONINDONESIA</b>.
        </p>
        <h5 class="text-left mt-3">
          Visi
        </h5>
        <p class="text-left about-desc">Menjadi Pilihan Utama Platform Jual Beli Online di Indonesia</p>
        <h5 class="text-left mt-3">
          Misi
        </h5>
        <ul class="text-left" style="list-style-type:disc;padding-left: 20px;">
          <li>
            &bull; Dengan semangat memandu masyarakat dan bisnis untuk terus mengikuti perubahan teknologi dalam jual
            beli
            online.
          </li>
          <li>
            &bull; Menyediakan pengalaman belanja online yang terbaik bagi konsumen Indonesia, dengan fokus pada
            kemudahan,
            keamanan, dan kepuasan pelanggan.
          </li>
        </ul>

        <h2 class="my-5">Tentang Omi Storefront</h2>
        <h3 class="text-left">Kenalan sama Omi Storefront</h3>
        <p class="text-left about-desc">
          Berawal dari permasalahan Seller/pedagang online tentang persaingan harga yang ketat dan biaya admin yang
          sangat tinggi
          yang pada akhirnya membuat tidak maksimalnya profit penjualan yang didapatkan.
        </p>
        <p class="text-left about-desc">
          Sebagai platform "toko mandiri" dengan User Interface serupa tapi beda dengan marketplace, Omi Storefront
          menawarkan
          fitur lebih unggul!
        </p>
        <p class="text-left about-desc">Saatnya beralih ke Omi Storefront,Anda dapat mengelola produk, mengirim pesanan,
          menerima pembayaran, dan mendapatkan
          rekapan penjualan dengan mudah dan efisien.</p>
        <p class="text-left about-desc">Optimalkan penjualan Anda bersama Omi Storefront dengan fee seller atau biaya
          admin mulai dari 1%</p>

        <h2 class="mt-5">Keunggulan Kami</h2>
        <ul class="text-left">
          <li>
            <h6>&bull; Fee terjangkau hampir 0</h6>
            <p class="about-desc ml-3">Fee sangat rendah meningkatkan profit seller dengan margin keuntungan lebih besar
              dan
              dapat memberikan harga kompetitif
              bagi customer</p>
          </li>
          <li>
            <h6>&bull; Customer Care 24/7</h6>
            <p class="about-desc ml-3">Siap membantu Anda dalam masalah transaksi, pertanyaan produk, atau masalah lain
              kapan saja 24/7 melalui WhatsApp
              ONMARKET</p>
          </li>
          <li>
            <h6>&bull; Budget Promosi up to Rp 10 Juta melalui KOL</h6>
            <p class="about-desc ml-3">Kolaborasi dengan Ibooming (Tiktok Official Partner), Top seller diberi modal
              promosi produk oleh KOL dengan target
              audience tepat, dengan cara yang autentik & terpercaya yang menunjang konversi penjualan yang signifikan
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>