<!-- <mat-tab label="Produk"> -->
<!-- <ng-template mat-tab-label>
        <a [routerLink]="['.']" [queryParams]="{ tab: 'product' }"
            queryParamsHandling="merge"></a>
    </ng-template> -->
<!-- <app-store-profile></app-store-profile> -->
<div class="row" id="1">
  <div class="col-12 col-lg-3">
    <div class="sticky-side">
      <aside class="sidebar">
        <h4 class="cat-title bold">
          <span class="material-icons">filter_alt</span> FILTER
        </h4>
        <!-- Single Widget -->

        <!-- Single Widget -->
        <!-- <div class="single-widget"> -->
        <!-- Post Widget -->
        <!-- <div class="accordions widget catagory-widget" id="cat-accordion">
                            <div class="single-accordion">
                                <h5>
                                    <a role="button"
                                        class="collapse show text-uppercase d-block p-3 bold"
                                        data-toggle="collapse" href="#accordion2">ON PROMO
                                    </a>
                                </h5> -->
        <!-- Post Widget Content -->
        <!-- <div id="accordion2"
                                    class="accordion-content widget-content collapse show"
                                    data-parent="#post-accordion"> -->
        <!-- Post Widget Items -->
        <!-- <ul class="widget-items">
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox" class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Voucher Diskon</label>
                                            </div>
                                        </li> -->
        <!-- <li>
                                            <div class="filter">
                                                <input type="checkbox" class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Diskon</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox" class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Cashback</label>
                                            </div>
                                        </li> -->
        <!-- </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->

        <!-- <div class="single-widget">
        
          <div class="accordions widget catagory-widget" id="cat-accordion">
            <div class="single-accordion">
              <h5>
                <a
                  role="button"
                  class="collapse show text-uppercase d-block p-3 bold"
                  data-toggle="collapse"
                  href="#accordion3"
                  >Pengiriman
                </a>
              </h5>
       
              <div
                id="accordion3"
                class="accordion-content widget-content collapse show"
                data-parent="#post-accordion"
              >
           
                <ul class="widget-items">
                  <li>
                    <div class="filter">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label pl-1" for="exampleCheck1"
                        >ONDELIVERY</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="filter">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label pl-1" for="exampleCheck1"
                        >Go Send</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="filter">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label pl-1" for="exampleCheck1"
                        >Paxel</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="filter">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label pl-1" for="exampleCheck1"
                        >J&T</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="filter">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label pl-1" for="exampleCheck1"
                        >JNE</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="filter">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label pl-1" for="exampleCheck1"
                        >Pos Indonesia</label
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Single Widget -->
        <div class="single-widget">
          <!-- Post Widget -->
          <div class="accordions widget post-widget" id="post-accordion">
            <div class="single-accordion">
              <h5>
                <a
                  role="button"
                  class="collapse show text-uppercase d-block p-3 bold"
                  data-toggle="collapse"
                  href="#accordion4"
                  >Batas Harga
                </a>
              </h5>
              <!-- Post Widget Content -->
              <div
                id="accordion4"
                class="accordion-content widget-content collapse show"
                data-parent="#post-accordion"
              >
                <!-- Post Widget Items -->
                <div class="p-3">
                  <input
                    matInput
                    type="text"
                    [(ngModel)]="minsPrice"
                    value="{{ minPrice }}"
                    [formControl]="form.controls['minPrice']"
                    (input)="onMinChange($event.target.value)"
                    (keyup)="minPriceFilter($event)"
                    class="form-control"
                    placeholder="Harga Terendah"
                  />
                </div>
                <div class="p-3">
                  <input
                    matInput
                    type="text"
                    [(ngModel)]="maxsPrice"
                    value="{{ maxPrice }}"
                    [formControl]="form.controls['maxPrice']"
                    (input)="onMaxChange($event.target.value)"
                    (keyup)="maxPriceFilter($event)"
                    class="form-control"
                    placeholder="Harga Tertinggi"
                  />
                </div>
                <div class="w-100">
                  <button class="btn" (click)="search()">Cari Harga</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </div>
  <ng-container
    *ngIf="storeProducts?.length > 0 && !isLoading; else cardSkeleton"
  >
    <div class="col-12 col-lg-9 pt-5">
      <div class="row">
        <div
          class="d-none d-md-block col-md-4 col-lg-3 mb-3 pl-1 pr-1"
          *ngFor="let storeProduct of storeProducts"
        >
          <a href="/product?product_id={{ storeProduct.product_id }}">
            <mat-card
              class="content h-100"
              *ngIf="!isLoading; else cardSkeleton"
            >
              <div style="display: flex; justify-content: center">
                <img
                  mat-card-image
                  class="product-card-image"
                  src="{{ storeProduct.main_photo_url }}"
                  alt="product image"
                  style="border-radius: 5px"
                />
              </div>

              <mat-card-content>
                <div>
                  <p class="over">{{ storeProduct.name }}</p>
                </div>
                <div>
                  <p class="bold" style="margin-bottom: 0px; font-size: 13px">
                    Rp {{ priceTransform(storeProduct.min_price) }}
                  </p>
                </div>
              </mat-card-content>
              <mat-card-footer align="end">
                <!-- <div>
                  <p class="desc">
                    <i class="marker fas fa-star"></i
                    ><i class="marker fas fa-star"></i
                    ><i class="marker fas fa-star"></i
                    ><i class="marker fas fa-star"></i
                    ><i class="marker fas fa-star"></i>
                  </p>
                </div> -->
                <div>
                  <p class="desc">
                    <i class="marker fas fa-map-marker-alt"></i>
                    {{ storeProduct.city }}
                  </p>
                </div>
              </mat-card-footer>
            </mat-card>
          </a>
        </div>
      </div>
      <mat-card
        class="d-block .d-sm-none d-md-none mb-3"
        *ngFor="let storeProduct of storeProducts"
      >
        <a href="/product?product_id={{ storeProduct.product_id }}">
          <div class="col-12">
            <div class="row d-flex flex-wrap">
              <div class="col-4 col-md-2 pl-0 pr-0">
                <img
                  class="align-self-center thumbnail-cart"
                  src="{{ storeProduct.main_photo_url }}"
                  alt=""
                />
              </div>
              <div class="col-8 col-md-6">
                <p class="over">{{ storeProduct.name }}</p>
                <p class="bold">
                  Rp {{ priceTransform(storeProduct.min_price) }}
                </p>
                <!-- <p class="desc text-right">
                  <i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i>
                </p> -->
                <p class="desc text-right">
                  <i class="marker fas fa-map-marker-alt"></i>
                  {{ storeProduct.city }}
                </p>
              </div>
            </div>
          </div>
        </a>
      </mat-card>

      <div class="row">
        <div class="col-12">
          <!-- Pagination -->
          <ul class="pagination justify-content-center py-4">
            <li class="px-1" [ngClass]="{ disabled: pageIndex === 0 }">
              <a
                href="javascript:void(0)"
                aria-label="Previous"
                (click)="arrowPagination('previous')"
              >
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li
              class="px-1"
              *ngFor="let page of totalPageSize; index as i"
              [ngClass]="{ active: pageIndex === i }"
            >
              <a href="javascript:void(0)" (click)="numberPagination(i)">{{
                i + 1
              }}</a>
            </li>
            <li
              [ngClass]="{ disabled: pageIndex === totalPageSize.length - 1 }"
            >
              <a
                href="javascript:void(0)"
                aria-label="Next"
                (click)="arrowPagination('next')"
              >
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #cardSkeleton>
    <div class="col-12 col-lg-9 pt-5">
      <div class="row">
        <div
          *ngFor="let _ of [].constructor(6); let i = index"
          class="d-none d-md-block col-md-4 col-lg-3 mb-3 pl-1 pr-1 card mb-3"
        >
          <mat-card class="content h-100">
            <div style="display: flex; justify-content: center">
              <div class="skeleton product-card-image"></div>
            </div>
            <mat-card-content>
              <div style="padding-top: 10px">
                <div class="skeleton" style="width: 40%; height: 15px"></div>
              </div>
            </mat-card-content>

            <mat-card-footer align="end" style="padding-top: 10px">
              <div>
                <div
                  class="skeleton"
                  style="width: 60px; height: 15px; margin-bottom: 5px"
                ></div>
              </div>
              <div>
                <div
                  class="skeleton"
                  style="
                    width: 50%;
                    height: 15px;
                    margin-left: auto;
                    padding-top: 15px;
                  "
                ></div>
              </div>
              <div style="padding-top: 5px">
                <div
                  class="skeleton"
                  style="width: 60%; height: 15px; margin-left: auto"
                ></div>
              </div>
            </mat-card-footer>
          </mat-card>
        </div>
        <div
          *ngFor="let _ of [].constructor(6); let i = index"
          class="d-block .d-sm-none d-md-none mb-3"
        >
          <mat-card class="content h-100">
            <div style="display: flex; justify-content: center">
              <div class="skeleton product-card-image"></div>
            </div>
            <mat-card-content>
              <div style="padding-top: 10px">
                <div class="skeleton" style="width: 40%; height: 15px"></div>
              </div>
            </mat-card-content>
            <mat-card-footer align="end" style="padding-top: 10px">
              <div>
                <div
                  class="skeleton"
                  style="width: 60px; height: 15px; margin-bottom: 5px"
                ></div>
              </div>
              <div>
                <div
                  class="skeleton"
                  style="
                    width: 50%;
                    height: 15px;
                    margin-left: auto;
                    padding-top: 15px;
                  "
                ></div>
              </div>
              <div style="padding-top: 5px">
                <div
                  class="skeleton"
                  style="width: 60%; height: 15px; margin-left: auto"
                ></div>
              </div>
            </mat-card-footer>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #elseNoProduct>
    <div class="row justify-content-center" style="justify-content: center">
      <div>
        <img
          class="shopping-cart-img"
          style="align-self: center; width: 250px"
          src="/assets/img/inventory.png"
        />
      </div>
    </div>
    <div style="text-align: center">
      <div style="color: #1d1d1d">
        <br />
        Belum ada produk
      </div>
    </div>
  </ng-template>
</div>
<!-- </mat-tab> -->
