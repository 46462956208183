import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";
import * as dayjs from "dayjs";
import "dayjs/locale/id";
import { CheckoutConfirmation } from "../models/checkout/checkout";

@Component({
  selector: "app-checkout-confirmation",
  templateUrl: "./checkout-confirmation.component.html",
  styleUrls: ["./checkout-confirmation.component.css"],
})
export class CheckoutConfirmationComponent implements OnInit {
  paymentUrl: string;
  orderSummary: any;
  orderInfo: CheckoutConfirmation[] = [];
  // {
  //   orders: [
  //     {
  //       buyer_note: string;
  //       products: [
  //         {
  //           cart_id?: number;
  //           product_id: number;
  //           quantity: number;
  //           variant_id: number;
  //         }
  //       ];
  //       product_name: string;
  //       variant_name: string;
  //       quantity: number;
  //       variant_price: number;
  //       subtotal: number;
  //     }
  //   ];
  //   service_name: string;
  //   delivery_fee: number;
  //   overall_price: number;
  //   final_price: number;
  //   insurance_exist: boolean;
  //   insurance_fee: number;
  //   voucher_id: number;
  //   total_discount: number;
  // };
  invoice: string;
  storeName: string;
  payment_id: string;
  expiryDate: string;
  serviceId: number;
  invoiceDetails: any;
  interval: any;

  constructor(
    // private snackBar: MatSnackBar,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private routes: ActivatedRoute,
    private router: Router
  ) {}

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit(): void {
    this.routes.queryParamMap.subscribe((params) => {
      this.orderSummary = JSON.parse(atob(params.get("orderSummary")));
      this.payment_id = this.orderSummary.payment_id;
      console.log(this.orderSummary, "params");
      this.paymentUrl = this.orderSummary.payment_url;
      this.storeName = this.orderSummary.orderSummary.store_name;

      for (let i = 0; i < this.orderSummary.orderSummary.orders.length; i++) {
        this.serviceId =
          this.orderSummary.orderSummary.orders[i].shipping.service_id;
      }

      // console.log(
      //   "Order Summary: ",
      //   this.orderSummary,
      //   typeof this.orderSummary
      // );
    });
    // if (
    //   history.state.orderSummary != null ||
    //   history.state.orderSummary != undefined
    // ) {
    //   this.orderSummary = history.state.orderSummary[0];
    //   if (this.orderSummary.voucher_id) {
    //     this.orderSummary.total_discount =
    //       this.orderSummary.overall_price - this.orderSummary.final_price;
    //   }
    //   this.paymentUrl = history.state.payment_url;
    //   this.invoice = history.state.invoice;
    //   // console.log("orderSummary")
    //   // console.log(history.state.orderSummary)
    // } else {
    //   // this.routes.navigateByUrl('/**')
    // }
    this.checkInvoice();
    this.interval = setInterval(() => {
      this.checkInvoice();
    }, 10000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  checkInvoice() {
    dayjs.locale("id");
    this.service.invoiceDetail(this.payment_id).subscribe((data) => {
      // console.log(data);
      this.expiryDate = dayjs(data.expired_at).format(
        "dddd, D MMMM YYYY, HH:mm"
      ); // Use dayjs for formatting

      if (data.status === "PAID" || data.status === "SETTLED") {
        this.router.navigateByUrl("/user/order");
      }
    });
  }

  serviceName() {
    switch (this.serviceId) {
      case 3:
        return "Reguler";
      case 7:
        return "Kargo";
      case 9:
        return "Ambil Ditempat";
    }
  }

  copyToClipboard(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  makeOrder() {
    window.open(this.paymentUrl);
  }
}
