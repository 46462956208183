<div class="contact-box bg-white rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
  <h3 style="font-size:20px">Wishlist</h3>
  <p class="mt-2" *ngIf="!isLoading && data.length>0">Menampilkan {{data.length+(pageSize*pageIndex)}} dari {{total}}
  </p>
  <div class="skeleton" *ngIf="isLoading" style="width: 200px;height: 14px;"></div>
  <div class="product-wrapper" *ngIf="!isLoading;else Skeletoncontainer">
    <ng-container *ngIf="data.length>0;else empty">
      <ng-container *ngFor="let item of data;let i=index">
        <div class="card-wrapper">
          <div class="product-card">
            <a [href]="'product?product_id='+item.product_id"
              [ngClass]="{'disabled':item.product_stock===0 || !item.product_available}" class="product-card-header"
              target="_blank">
              <img [src]="item.photo_url" />
            </a>
            <a [href]="'product?product_id='+item.product_id" target="_blank">
              <div class="product-card-body">
                <h4>{{item.name}}</h4>
                <span>Variasi : {{item.variant_name}}</span>
                <h5>Rp {{priceTransform(item.price)}}</h5>
                <p class="text-muted">{{item.store_name}}</p>
                <div class="d-flex align-items-center justify-content-between text-muted" style="font-size: 11px;">
                  <p class="m-0 d-flex align-items-center star-rating" style="gap:3px"><mat-icon
                      style="font-size: 14px;width: 14px;height: 14px;color: orange;">
                      star</mat-icon>
                    {{item.avg_rating}}</p>
                  <p class="m-0 d-flex align-items-center city" style="gap:3px"><mat-icon
                      style="font-size: 12px;width: 12px;height: 12px;">
                      location_on</mat-icon>
                    {{item.city|transformCity}}</p>

                </div>
              </div>
            </a>
            <div class="product-card-footer">
              <button class="delete-btn"><mat-icon class="delete-icon" (click)="delete(item)">delete</mat-icon></button>
              <button class="add-cart-btn" [ngClass]="{'disabled':item.product_stock===0 || !item.product_available}"
                (click)="addToCard(item)" [disabled]="item.product_stock===0||!item.product_available">Tambah
                Keranjang</button>
            </div>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </div>
  <div class="row" *ngIf="data.length>0">
    <div class="col-12">
      <ul class="pagination justify-content-center py-4">
        <li class="px-1" [ngClass]="{ disabled: pageIndex === 0 }">
          <a href="javascript:void(0)" aria-label="Previous" (click)="arrowPagination('previous')">
            <i class="fas fa-arrow-left"></i>
          </a>
        </li>
        <li class="px-1" *ngFor="let page of maxPageSize; index as i" [ngClass]="{ active: pageIndex === i }">
          <a href="javascript:void(0)" style="background-color: white;border:1px solid #cecece;border-radius: 5px;"
            (click)=" numberPagination(i)">{{ i + 1
            }}</a>
        </li>
        <li [ngClass]="{disabled: pageIndex === maxPageSize.length - 1}">
          <a href="javascript:void(0)" aria-label="Next" (click)="arrowPagination('next')">
            <i class="fas fa-arrow-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #empty>
  <div class="d-flex justify-content-center align-items-center flex-column w-100">
    <img width="150" height="150" src="../../../../../../assets/img/wishlist.png">
    <h4 style="font-weight: 700;font-size: 16px;" class="mb-1">Wishlist Kamu Kosong!</h4>
    <p>Simpan produk yang kamu minati untuk dibeli nanti.</p>
  </div>
</ng-template>

<ng-template #Skeletoncontainer>
  <div class="product-wrapper">
    <ng-container *ngFor="let _ of [].constructor(12); let i = index">
      <div class="card-wrapper">
        <div class="product-card">
          <div class="product-card-header skeleton" style="height: 150px;">
          </div>
          <div class="product-card-body">
            <h4 class="skeleton mt-1" style="width: 100%;"></h4>

            <span class="skeleton mt-1" style="width: 100%;"></span>
            <h5 class="skeleton mt-1" style="width: 100%;"></h5>
            <p class="text-muted mt-1 skeleton" style="width: 100%;"></p>
            <div class="d-flex mt-1 align-items-center justify-content-between text-muted" style="font-size: 11px;">
              <p class="m-0 d-flex align-items-center star-rating skeleton" style="gap:3px;width: 18%;"></p>
              <p class="m-0 d-flex align-items-center city skeleton" style="gap:3px; width: 70%;">
              </p>
            </div>
          </div>
          <div class="product-card-footer">
            <div class="skeleton" style=" width: 30px;
          height: 30px;"></div>
            <div class=" skeleton" style=" height: 30px;width: 60%;"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>