<div class="card">
  <div class="row h-100">
    <div class="chat-container col-md-3" [ngClass]="{'open':!activeChat}">
      <div class="d-flex align-items-center" style="height: 70px;">
        <h3 class="font-weight-bold ml-3 mt-0">CHAT</h3>
      </div>
      <mat-divider></mat-divider>
      <!-- <div *ngIf="chatLoading" style="position: absolute; left: 50%; ">
        <div style="position: relative; left: -50%;top: 10px;">
          <mat-spinner diameter="25"></mat-spinner>
          
        </div>
      </div> -->
      <div *ngIf="chatLoading" class="chat-list">
        <div class="chat-card p-2" style="border:1px solid #f0f3f7" *ngFor="let _ of[].constructor(6) ">
          <div class="d-flex" style="gap: 10px;width: 100%;">
            <div class="skeleton user_img"></div>
            <div class="py-1" style="width: 100%;flex-shrink: 1;overflow: hidden;">
              <div class="skeleton" style="height: 18px; width: 100%;border-radius: 5px;"></div>
              <div class="skeleton mt-1" style="height: 14px; width: 100%;border-radius: 5px;"></div>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="!chatLoading" class="chat-list">
        <div *ngFor="let item of chats" class="chat-card p-2" [ngClass]="{'active': activeChat === item.id}"
          (click)="setActive(item)">
          <div class="d-flex" style="gap: 10px;">
            <div class="user_img ">
              <img [src]="item.image_url" (error)="onImageError($event)">
            </div>
            <div class="py-1" style="width: 100%;flex-shrink: 1;overflow: hidden;">
              <h4 class="font-weight-bold">{{item.store_name}}</h4>
              <div class="d-flex justify-content-between">
                <div *ngIf="item.last_message?.type==='text'">
                  <p *ngIf="item.last_message?.from === loggedinUser" class="text-muted mb-0"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    Saya: {{ item.last_message?.content.text }}
                  </p>
                  <p *ngIf="item.last_message?.from !== loggedinUser" class="text-muted mb-0"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ item.last_message?.content.text }}
                  </p>
                </div>
                <div *ngIf="item.last_message?.type==='image'">
                  <p *ngIf="item.last_message?.from === loggedinUser" class="text-muted mb-0 d-flex align-items-center"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    Saya: <mat-icon style="font-size:16px;height: unset;width: unset;">image</mat-icon> Image
                  </p>
                  <p *ngIf="item.last_message?.from !== loggedinUser" class="text-muted mb-0 d-flex align-items-center"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    <mat-icon style="font-size:16px;height: unset;width: unset;">image</mat-icon> Image
                  </p>
                </div>
                <div class="badge badge-warning" *ngIf="item.unread>0">
                  {{ item.unread }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-md-9 message-container" [ngClass]="{'open':activeChat}">
      <ng-container *ngIf="activeChat else emptyMessage"><app-message [chatId]="activeChat" [username]="activeUsername"
          [storename]="storeName" [activeProfilePicture]="activeProfilePicture" [activeLastOnline]="activeLastOnline"
          [storeUrl]="storeUrl" (backClicked)=" back()"></app-message></ng-container>
      <ng-container #emptyMessage>
        <div class="w-100 d-flex justify-content-center align-items-center flex-column h-100">
          <img src="../../../../../../assets//img/chat.png" alt="empty-message" width="200" height="200" />
          <h4 style="font-weight: 700;font-size:20px">Mulai percakapan dengan penjual!</h4>
          <p class="text-muted">Pilih pesan di samping untuk memulai percakapan.</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>