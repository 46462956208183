<ng-template [ngIf]="!isLoading">
  <ng-container *ngIf="cartId === null; else cardComponents"> </ng-container>

  <ng-template #cardComponents>
    <div class="blog">
      <div class="main">
        <app-scrollup></app-scrollup>
        <app-whatsapp-corner></app-whatsapp-corner>
        <!-- <app-message-corner></app-message-corner> -->
        <app-main-header></app-main-header>
        <section id="blog" class="section blog-area bg-gray mb-3 pb_50">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <div class="col-12 py-4">
                  <div class="card">
                    <div class="card-body py-4">
                      <div class="col-12 d-flex flex-row flex-wrap">
                        <ng-template [ngIf]="primaryAddress">
                          <div
                            class="d-flex flex-row flex-wrap col-lg-12 col-md-12"
                            style="padding: 0px"
                          >
                            <div class="col-lg-9 col-md-8">
                              <div class="d-flex flex-column pr-2">
                                <h4>
                                  <i class="fas fa-map-marked-alt"></i
                                  ><span class="ml-2"></span>Alamat Pengiriman
                                </h4>
                              </div>
                              <div class="d-flex flex-column pt-2">
                                <div class="d-flex flex-row flex-wrap">
                                  <p class="gray bold" style="margin: 0px">
                                    {{ primaryAddress.recipient }}
                                  </p>
                                  <div class="divider d-none d-md-block"></div>
                                  <p
                                    class="gray pr-3"
                                    style="margin: 0px; font-size: 11px"
                                  >
                                    (+62)
                                    {{ primaryAddress.phone.substring(1) }}
                                  </p>
                                  <ng-template
                                    [ngIf]="primaryAddress.primary_address"
                                  >
                                    <p class="text-center highlight">Utama</p>
                                  </ng-template>
                                </div>
                              </div>
                              <div class="d-flex flex-column py-1">
                                <p style="margin-bottom: 0px">
                                  Kategori Alamat :
                                  {{ primaryAddress.category }}
                                </p>
                                <p class="gray" style="margin: 0px">
                                  {{ primaryAddress.address_input }},
                                  {{ primaryAddress.urban }},
                                  {{ primaryAddress.city }},
                                  {{ primaryAddress.province }}, ID
                                  {{ primaryAddress.post_id }}
                                </p>
                              </div>
                            </div>
                            <div
                              class="col-lg-3 col-md-4 d-flex align-items-center"
                            >
                              <button
                                (click)="
                                  selectAddress(primaryAddress.address_id)
                                "
                                class="btn-checkout m-1"
                                style="background: #3571b6"
                              >
                                Ubah Alamat
                              </button>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template [ngIf]="!primaryAddress">
                          <div
                            class="d-flex flex-row flex-wrap col-lg-12 col-md-12"
                            style="padding: 0px"
                          >
                            <div class="col-lg-9 col-md-8">
                              <div class="d-flex flex-column pr-2">
                                <h4>
                                  <i class="fas fa-map-marked-alt"></i
                                  ><span class="ml-2"></span>Alamat Pengiriman
                                </h4>
                              </div>
                              <div class="d-flex flex-column pt-2">
                                <div class="d-flex flex-row flex-wrap">
                                  <p class="gray bold" style="margin: 0px">
                                    Belum ada alamat yang terdaftar
                                  </p>
                                  <div class="divider d-none d-md-block"></div>
                                  <p
                                    class="gray pr-3"
                                    style="margin: 0px; font-size: 11px"
                                  >
                                    Silahkan tambah alamat terlebih dahulu
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-3 col-md-4 d-flex align-items-center"
                            >
                              <a
                                class="btn-checkout m-1"
                                (click)="openDialog('Tambah', {})"
                                type="submit"
                                style="background: #b6244f"
                                >Tambah Alamat</a
                              >
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 w-100">
                  <ng-container
                    *ngFor="let store of reviewInfo; let i = index"
                    class="mb-4"
                  >
                    <div class="mb-4">
                      <div class="card">
                        <div class="card-body pt-4 py-2">
                          <div class="col-12">
                            <div
                              class="row d-flex flex-wrap header pr-4 py-2"
                              style="color: #8b8b8b"
                            >
                              <div class="col-5 d-none d-md-block">
                                <div style="font-size: 14px">
                                  <i class="fas fa-store"></i
                                  ><span class="ml-2"></span>
                                  {{ store.store_name }}
                                </div>
                              </div>
                              <div
                                class="col-2 d-none d-md-block align-self-center"
                              >
                                Jumlah
                              </div>
                              <div
                                class="col-2 d-none d-md-block align-self-center"
                              >
                                Harga Satuan
                              </div>
                              <div
                                class="col-3 d-none d-md-block align-self-center text-right"
                              >
                                Subtotal Produk
                              </div>
                            </div>
                            <mat-divider class="py-2"></mat-divider>
                            <div *ngFor="let product of store.products">
                              <div class="row d-flex flex-wrap pr-4 py-2">
                                <div
                                  class="col-lg-5 col-md-5 col-sm-12 d-flex flex-row"
                                >
                                  <a
                                    href="/product?product_id={{
                                      product.product_id
                                    }}"
                                  >
                                    <img
                                      class="align-self-center mb-2 image-costumer mr-3"
                                      style="width: 70px"
                                      [src]="product.img"
                                      alt=""
                                    />
                                  </a>
                                  <div>
                                    <a
                                      href="/product?product_id={{
                                        product.product_id
                                      }}"
                                    >
                                      <h4 style="margin: 0px">
                                        {{ product.name }}
                                      </h4>
                                    </a>
                                    <p class="text-muted">
                                      Variasi : {{ product.variant }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="col-lg-2 col-md-3 col-sm-12 align-self-center"
                                >
                                  <p>{{ product.quantity }} Pcs</p>
                                </div>
                                <div
                                  class="col-lg-2 col-md-2 col-sm-12 align-self-center"
                                >
                                  <p>
                                    {{ "Rp"
                                    }}{{
                                      priceTransform(product.variant_price)
                                    }}
                                  </p>
                                </div>
                                <div
                                  class="col-lg-3 col-md-2 col-sm-12 align-self-center text-right"
                                >
                                  <p>
                                    {{ "Rp"
                                    }}{{ priceTransform(product.total_price) }}
                                  </p>
                                </div>
                              </div>
                              <mat-divider class="py-2"></mat-divider>
                            </div>
                            <div class="row d-flex flex-wrap col-lg-12">
                              <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                  <label>Pesan untuk penjual : </label>
                                  <input
                                    matInput
                                    #notes
                                    type="text"
                                    [(ngModel)]="notesArray[i]"
                                    class="form-control"
                                    style="border: 1px solid #cecaca"
                                  />
                                  <small
                                    id="emailHelp"
                                    class="form-text text-muted"
                                    >Tulis Pesan atau catatan kepada
                                    penjual</small
                                  >
                                </div>
                              </div>
                              <div class="col-md-5 col-sm-12 d-flex flex-row">
                                <div class="col-lg-12 col-md-12 col-sm-12 pl-0">
                                  <p>Opsi Pengiriman:</p>
                                  <ng-container
                                    *ngIf="
                                      primaryAddress !== null &&
                                        primaryAddress !== undefined;
                                      else elseNoAddress
                                    "
                                  >
                                    <p
                                      class="clickable"
                                      style="color: #3571b6"
                                      (click)="
                                        pickServiceFromCart(i, store, true)
                                      "
                                    >
                                      <strong>{{
                                        selectedExpeditions[i]
                                      }}</strong>
                                    </p>
                                    <p>
                                      {{ "Rp"
                                      }}{{
                                        selectedExpeditionsPrice.length > 0
                                          ? priceTransform(
                                              selectedExpeditionsPrice[i]
                                            )
                                          : "0"
                                      }}
                                    </p>
                                  </ng-container>
                                  <ng-template #elseNoAddress>
                                    <p
                                      class="clickable"
                                      style="color: #3571b6"
                                      (click)="openDialog('Tambah', {})"
                                    >
                                      <strong>Mohon Input Alamat</strong>
                                    </p>
                                  </ng-template>
                                  <mat-checkbox
                                    [(ngModel)]="insuranceUsedArray[i]"
                                    (change)="checkCheckBoxValueCart($event, i)"
                                  >
                                    Asuransi
                                  </mat-checkbox>
                                </div>
                              </div>

                              <div
                                class="col-lg-3 col-md-4 col-sm-12 pr-2 justify-align-right text-right"
                              >
                                <p class="bold"></p>
                                <p class="bold"></p>
                                <ng-container
                                  *ngIf="
                                    primaryAddress !== null &&
                                    primaryAddress !== undefined
                                  "
                                >
                                  <span class="bold">Sub Total :</span>
                                  <span class="bold">
                                    {{ "Rp"
                                    }}{{
                                      priceTransform(
                                        getTotalPrice(store.products)
                                      )
                                    }}
                                  </span>
                                </ng-container>
                              </div>
                            </div>
                            <mat-divider class="py-2"></mat-divider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <div class="col-12 w-100">
                  <div class="card">
                    <div class="card-body pt-4 py-2">
                      <div class="col-12">
                        <div
                          class="row d-flex flex-wrap header pr-4 py-2"
                          style="color: #8b8b8b"
                        >
                          <div class="col-5 d-none d-md-block">
                            <h4>
                              <fa-icon [icon]="faMoney"></fa-icon
                              ><span class="ml-2"></span> Metode Pembayaran
                            </h4>
                          </div>
                          <div
                            class="col-2 d-none d-md-block align-self-center"
                          ></div>
                          <div
                            class="col-2 d-none d-md-block align-self-center"
                          ></div>
                          <div
                            class="col-5 d-none d-md-block align-self-center text-right"
                            class="form-group"
                          >
                            <select
                              class="form-control selectpicker clickable"
                              [formControl]="form.controls['payment']"
                            >
                              <option value="" disabled selected>
                                - Pilih Metode Pembayaran -
                              </option>
                              <option value="EWallet">E-Wallet</option>

                              <option
                                *ngIf="selectedCodAcitivated === true"
                                value="COD"
                              >
                                COD
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <!-- <div class="col-12 col-lg-6"> -->
                <div class="sticky-side" style="padding-top: 25px">
                  <aside class="sidebar">
                    <div class="card">
                      <div class="card-body py-4 py-2">
                        <div class="d-flex flex-column">
                          <div
                            class="d-flex flex-column pr-2"
                            style="padding-left: 15px"
                          >
                            <h4>
                              <fa-icon [icon]="faBasketShopping"></fa-icon
                              ><span class="ml-2"></span>Ringkasan Belanja
                            </h4>
                          </div>
                          <div class="d-flex">
                            <div
                              class="col-sm-8 d-flex flex-column d-none d-md-block"
                            >
                              <p id="total-cart">Total Harga Produk:</p>
                              <ng-container
                                *ngIf="
                                  primaryAddress !== null &&
                                  primaryAddress !== undefined
                                "
                              >
                                <p
                                  style="padding-top: 5px; padding-bottom: 5px"
                                  id="total-cart"
                                >
                                  Total Ongkos Kirim:
                                </p>
                              </ng-container>
                              <ng-container *ngIf="insuranceFee > 0">
                                <p
                                  style="padding-top: 5px; padding-bottom: 5px"
                                  id="total-cart"
                                >
                                  Biaya Asuransi:
                                </p>
                              </ng-container>
                            </div>
                            <div style="text-align: end">
                              <p id="total-cart">
                                {{ "Rp"
                                }}{{ priceTransform(productTotalPrice) }}
                              </p>
                              <ng-container
                                *ngIf="
                                  primaryAddress !== null &&
                                  primaryAddress !== undefined
                                "
                              >
                                <p
                                  style="padding-top: 5px; padding-bottom: 5px"
                                  id="total-cart"
                                >
                                  {{ "Rp" }}{{ priceTransform(deliveryPrice) }}
                                </p>
                              </ng-container>
                              <ng-container *ngIf="insuranceFee > 0">
                                <p
                                  style="padding-top: 5px; padding-bottom: 5px"
                                  id="total-cart"
                                >
                                  {{ "Rp" }}{{ priceTransform(insuranceFee) }}
                                </p>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container
                            *ngIf="
                              primaryAddress !== null &&
                              primaryAddress !== undefined
                            "
                          >
                            <div class="d-flex">
                              <div
                                class="col-sm-8 d-flex flex-column d-none d-md-block"
                              >
                                <ng-container
                                  *ngIf="
                                    discountedPrice > 0;
                                    else elseNoVoucherTitle
                                  "
                                >
                                  <h4 style="color: #b6244f">
                                    <strong> Total Tagihan: </strong>
                                  </h4>
                                  <!-- <h4 style="color: #b6244f">
                                  <strong>Total Akhir: </strong>
                                </h4> -->
                                </ng-container>
                                <ng-template #elseNoVoucherTitle>
                                  <h4 style="color: #b6244f">
                                    <strong>Total Tagihan: </strong>
                                  </h4>
                                </ng-template>
                              </div>
                              <div
                                style="text-align: end; justify-content: end"
                              >
                                <ng-container
                                  *ngIf="
                                    discountedPrice > 0;
                                    else elseNoVoucherPrice
                                  "
                                >
                                  <h4 style="color: #b6244f">
                                    <strong>
                                      {{ "Rp"
                                      }}{{ priceTransform(overallPrice) }}
                                    </strong>
                                  </h4>
                                  <!-- <h4 style="color: #b6244f">
                                  <strong
                                    >Rp
                                    {{
                                      priceTransform(discountedPrice)
                                    }}</strong
                                  >
                                </h4> -->
                                </ng-container>
                                <ng-template #elseNoVoucherPrice>
                                  <h4 style="color: #b6244f">
                                    <strong
                                      >{{ "Rp"
                                      }}{{
                                        priceTransform(overallPrice)
                                      }}</strong
                                    >
                                  </h4>
                                </ng-template>
                              </div>
                            </div>
                            <mat-divider class="py-2"></mat-divider>
                          </ng-container>
                          <button
                            [ngClass]="{
                              isDisabled:
                                0 >= overallPrice && this.services.length == 0
                            }"
                            style="background-color: #b6244f"
                            class="btn-checkout m-1"
                            (click)="checkout()"
                          >
                            Buat Pesanan
                          </button>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>

        <app-footer-one></app-footer-one>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template [ngIf]="isLoading">
  <div class="col ptb_50" style="text-align: center">
    <mat-spinner style="margin: 80px auto"></mat-spinner><br />
    <h4>Loading</h4>
  </div>
</ng-template>

<!-- <ng-template *ngIf="isLoading" >
  <div class="loading-over">
    <mat-spinner ></mat-spinner><br />
    <h4>Loading</h4>
  </div>
</ng-template> -->
