import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { reviewData } from '../../models/review-and-rating/review-and-rating.interface';
import { ApiService } from 'src/app/api.service';

type data = {
  review: reviewData,
  selectedImage: string
}

type listImage = {
  type: string,
  url: string
}

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss']
})
export class ReviewModalComponent implements OnInit {

  selectedImageIndex = 0
  imageList: listImage[] = []
  review: reviewData
  profileUrl: string
  constructor(
    public dialogRef: MatDialogRef<ReviewModalComponent>,
    private service: ApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: data,
  ) {
    this.review = data.review
    if (data.review.video) {
      this.imageList.push(
        {
          type: 'video',
          url: data.review.video
        }
      )
    }

    data.review.image.map(item => {
      this.imageList.push({
        type: 'image',
        url: item
      })
    })
    this.profileUrl = this.userProfileImg(data.review.username)
    this.selectedImageIndex = this.imageList.findIndex((item) => item.url === data.selectedImage)

  }

  ngOnInit(): void {

  }

  nextImage() {
    if (this.selectedImageIndex < this.imageList.length - 1) {
      this.selectedImageIndex++;
    }
  }

  prevImage() {
    if (this.selectedImageIndex > 0) {
      this.selectedImageIndex--;
    }
  }


  userProfileImg(username) {
    return this.service.API_USERSTATE_SERVER + `get-profile-img/${username}`
  }

  useDefaultuserProfileImg() {
    this.profileUrl = "assets/img/user-avatar.png";
  }
}
