<div class="card">
  <!-- Contact Form -->
  <form [formGroup]="form">
    <div class="contact-top">
      <h3 class="contact-title m-0">Ubah Password</h3>
      <h5 class="text-secondary fw-3 py-3">Ubah password akunmu disini</h5>
    </div>
    <div class="row">
      <div class="col-7">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><span class="material-icons">vpn_key</span></span
              >
            </div>
            <input
              type="password"
              class="form-control"
              placeholder="Password Lama"
              [formControl]="form.controls['old_password']"
            />
          </div>
          <mat-hint
            *ngIf="
              form.controls['old_password'].hasError('required') &&
              form.controls['old_password'].touched
            "
            class="text-danger font-14"
            >Masukkan Password Lama</mat-hint
          >
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><span class="material-icons">vpn_key</span></span
              >
            </div>
            <input
              type="password"
              class="form-control"
              placeholder="Password Baru"
              [formControl]="form.controls['new_password']"
            />
          </div>
          <mat-hint
            *ngIf="
              form.controls['new_password'].hasError('required') &&
              form.controls['new_password'].touched
            "
            class="text-danger font-14"
            >Masukkan Password Baru</mat-hint
          >
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><span class="material-icons">vpn_key</span></span
              >
            </div>
            <input
              type="password"
              placeholder="Konfirmasi Password Baru"
              class="form-control"
              minlength="6"
              [formControl]="form.controls['confirm_password']"
            />
          </div>
          <mat-hint
            *ngIf="
              form.controls['confirm_password'].hasError('required') &&
              form.controls['confirm_password'].touched
            "
            class="text-danger font-14"
            >Konfirmasi password baru</mat-hint
          >
          <mat-hint
            *ngIf="form.controls['confirm_password'].hasError('notEquivalent')"
            class="text-danger font-14"
            >Password tidak sesuai</mat-hint
          >
        </div>
        <div>
          <button
            mat-raised-button
            class="btn btn-lg mt-sm-3 w-500"
            type="submit"
            color="primary"
            (click)="resetPassword()"
            [disabled]="!form.valid"
          >
            Ganti Password
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
