import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
  providers: [ApiService],
})
export class SignupComponent implements OnInit {
  msg = "";
  form: FormGroup;
  showLoading: boolean = false;

  constructor(
    private service: ApiService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  hide: boolean = true;

  confirmHide: boolean = true;

  ngOnInit() {
    if (localStorage.getItem("jwt") != null) {
      this.router.navigate(["/profile"]);
    }
    this.form = this.fb.group({
      userName: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [
        "",
        Validators.compose([
          Validators.required,
          this.confirmPasswordValidation,
        ]),
      ],
      email: [null, [Validators.required, this.emailValidator()]],
      firstName: [null, Validators.compose([Validators.required])],
      lastName: [null, Validators.compose([Validators.required])],
      phoneNumber: [
        null,
        Validators.compose([
          Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$"),
          Validators.required,
        ]),
      ],
    });
  }

  confirmPasswordValidation: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return this.form?.value.password === control.value
      ? null
      : { PasswordNotMatch: true };
  };

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }

  toggleConfirmPasswordVisibility() {
    this.confirmHide = !this.confirmHide;
  }

  emailValidator() {
    return (control) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailRegex.test(control.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  onInputChange(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    event.target.value = inputValue;
  }

  register() {
    this.showLoading = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const allFieldsFilled = Object.values(this.form.value).every(
      (value) => value !== null && value !== "" && value !== undefined
    );

    const dataContent = {
      username: this.form.value.userName,
      password: this.form.value.password,
      email: this.form.value.email,
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      phone_number: "62" + this.form.value.phoneNumber.substring(1),
      mode: "OTP",
    };

    localStorage.setItem("signUpData", JSON.stringify(dataContent));

    if (allFieldsFilled) {
      this.service.register(dataContent).subscribe(
        (data) => {
          this.showLoading = false;
          const token = data.token;
          const expired = data.exp;
          const cleanedToken = token.replace(/"/g, "");
          console.log(cleanedToken);

          console.log(expired);
          localStorage.setItem("tokenDaftar", cleanedToken);
          localStorage.setItem("expired", JSON.stringify(expired));
          setTimeout(() => {
            this.router.navigate(["signup/otp"]);
          }, 1500);
        },
        (e) => {
          this.showLoading = false;
          Swal.fire({
            icon: "error",
            title: "Error",
            text: e.error.message,
          });
          if (e.error.message === "OTP still active, please wait 10 minutes") {
            setTimeout(() => {
              this.router.navigate(["signup/otp"]);
            }, 1500);
          }
        }
      );
    } else {
      this.showLoading = false;
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Pastikan semua data sudah lengkap.",
      });
    }

    // this.service.register(dataContent).subscribe(
    //   (data) => {
    //     const dialogRef = this.dialog.open(DialogSignUp, {
    //       data: {
    //         title: "Account created successfully.",

    //         heading: "Please login to utilize the account",
    //       },
    //     });

    //   },
    //   (e) => {
    //     if (e.status == 400 && e.error.message !== "Invalid JSON Format") {
    //       const dialogRef = this.dialog.open(DialogSignUp, {
    //         data: {
    //           title: "Account creation failed.",
    //           heading: e.error.message,
    //         },
    //         // disableClose: true
    //       });
    //     } else {
    //       const dialogRef = this.dialog.open(DialogSignUp, {
    //         data: {
    //           title: "Account creation failed.",
    //           heading: "Please try again later",
    //         },
    //       });
    //     }
    //   }
    // );
  }

  signIn() {
    this.router.navigateByUrl("/login");
  }
}

@Component({
  selector: "dialog-sign-up",
  templateUrl: "dialog-sign-up.html",
})
export class DialogSignUp {
  title: string;
  heading: string;

  constructor(
    public dialogRef: MatDialogRef<DialogSignUp>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private routes: Router
  ) {
    this.title = data.title;
    this.heading = data.heading;
  }

  OK(): void {
    if (this.title === "Account created successfully.") {
      this.dialogRef.close();
      this.routes.navigate([""]);
    } else {
      this.dialogRef.close();
    }
  }
}
