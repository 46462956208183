<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <section id="blog" class="section blog-area ptb_50">
      <div class="container">
        <div>
          <div class="col-12 pb-3">
            <div class="card">
              <div class="card-body py-4">
                <div class="col-12 d-flex flex-row flex-wrap" style="padding: 0px">
                  <div class="d-flex flex-column flex-wrap col-lg-6 col-md-12 pb-4" style="padding: 0px">
                    <div class="col-lg-12 col-md-12 d-flex flex-row flex-wrap" style="padding: 0px">
                      <div class="col-lg-3 col-md-3" style="padding-bottom: 10px; padding-right: 0px">
                        <img class="align-self-start image-shop img-fluid" [src]="imageUrl"
                          (error)="useDefaultProfileImg()" alt="" />
                      </div>
                      <div class="col-lg-9 col-md-9">
                        <div class="d-flex flex-column">
                          <h3 style="margin: 0px">{{ storeInfos.name }}</h3>

                          <p class="gray" style="font-size: 12px; margin: 0px">
                            {{ formatLastOnline() }}
                          </p>
                          <p class="gray d-flex align-items-center" style="font-size: 12px;margin-bottom:0">
                            <span class="material-icons gray">location_on</span>
                            {{ storeInfos.city }}
                          </p>
                          <button class="btn" (click)="handleChat()">Chat Seller</button>

                          <!-- <div
                            class="col-lg-12 col-md-12 d-flex flex-row flex-wrap"
                            style="padding: 0px"
                          >
                            <div class="pb-2 pr-1">
                              <button
                                class="chat-toko"
                                style="background: #13aad4"
                              >
                                <span class="material-icons pr-2">forum</span
                                >Chat Toko
                              </button>
                            </div>
                            <div class="pb-2">
                              <button class="chat-toko">
                                <span class="material-icons pr-2"
                                  >add_circle</span
                                >Follow
                              </button>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row flex-wrap col-lg-6 col-md-12 w-100" style="padding: 0px">
                    <div class="d-flex flex-row col-12" style="padding-bottom: 10px; padding-right: 0px">
                      <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-row flex-wrap mb-4" style="padding: 0px">
                        <div class="col-lg-5 col-md-6 col-sm-12 first">
                          <p href="#">
                            <span class="material-icons">sell</span>Produk
                            Terjual :
                            <a class="bold" style="color: #3571b6">{{
                              storeInfos.sold
                              }}</a>
                          </p>
                          <p href="#">
                            <span class="material-icons">reviews</span>Rating :
                            <a class="bold" style="color: #3571b6">{{
                              storeInfos.rating
                              }}</a>
                          </p>
                        </div>
                        <div class="col-lg-7 col-md-6 col-sm-12 first">
                          <p href="#">
                            <span class="material-icons">groups</span>Pengikut :
                            <a class="bold" style="color: #3571b6">{{
                              storeInfos.followers
                              }}</a>
                          </p>
                          <p href="#">
                            <span class="material-icons">forum</span>Performa
                            Chat :
                            <a class="bold" style="color: #3571b6">Hitungan menit</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-box bg-white rounded p-2">
            <nav mat-tab-nav-bar>
              <a style="color: black" mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link"
                [queryParams]="link.queryParams" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
                {{ link.label }}</a>
            </nav>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </section>
    <app-footer-one></app-footer-one>
  </div>
</div>