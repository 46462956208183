<div mat-dialog-title style="width:100%; margin: 0">
  <div class="pb-3 pt-1">
    <mat-icon style="float: right; margin-right: 10px; cursor: pointer" mat-dialog-close>close</mat-icon>
  </div>
</div>

<div mat-dialog-content>
  <div class="image-container">
    <div class="image-wrapper">
      <img *ngIf="imageList[selectedImageIndex].type==='image'" [src]="imageList[selectedImageIndex].url" />
      <video *ngIf="imageList[selectedImageIndex].type==='video'" [src]="imageList[selectedImageIndex].url" controls
        autoplay></video>

      <!-- Previous Button -->
      <div class="prev-button" (click)="prevImage()">
        <mat-icon>chevron_left</mat-icon>
      </div>

      <!-- Next Button -->
      <div class="next-button" (click)="nextImage()">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <div class="picture-wall">
      <ng-container *ngFor="let item of imageList;let i = index">
        <div *ngIf="item.type==='video'" class="video-container" [ngClass]="{'active':i===selectedImageIndex}"
          (click)="selectedImageIndex = i">
          <video [src]="item.url"></video>
          <div class="play-button">
            <mat-icon>play_circle_filled</mat-icon>
          </div>
        </div>

        <img *ngIf="item.type==='image'" [src]="item.url" alt="review_img" [ngClass]="{'active':i===selectedImageIndex}"
          (click)="selectedImageIndex = i" />
      </ng-container>
    </div>
  </div>
  <div class="review-container ml-4">
    <mat-divider vertical="true"></mat-divider>
    <div class="d-flex align-items-center flex-row" style="gap:5px">
      <div class="user_img">
        <img [src]="profileUrl" (error)="useDefaultuserProfileImg()">
      </div>
      <h3 class="m-0">{{review.username}}</h3>
      <p class="text-muted">{{review.created_at |timeAgo}}</p>

    </div>
    <div class="star-rating">
      <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
        <mat-icon *ngIf="i < review.rating">star</mat-icon>
        <mat-icon *ngIf="i >= review.rating">star_border</mat-icon>
      </ng-container>
    </div>
    <p class="mt-2 description">
      {{review.description}}
    </p>
  </div>
</div>